import { Injectable } from '@angular/core';
import { ValidationReleaseModel } from '@app/shared/models/validation-release.model';
import { StateToken, State, Selector, Action, StateContext } from '@ngxs/store';
import { AddValidationRelease } from './actions/add-validation-release.action';

const RELEASE_TOKEN = new StateToken<ValidationReleaseModel>('release');
@State<ValidationReleaseModel>({
  name: RELEASE_TOKEN,
  defaults: null
})
@Injectable()
export class ValidationReleaseState {
  @Selector()
  static getValidationRelease(state: ValidationReleaseModel): ValidationReleaseModel {
    return state;
  }

  @Action(AddValidationRelease)
  AddValidationRelease(ctx: StateContext<ValidationReleaseModel>, action: AddValidationRelease): void {
    ctx.setState(action.payload);
  }
}
