<div *ngIf="visibilityManagerService.isMenuVisible">
	<ng-container>
		<span class="unread" *ngIf="notifications && unreadBasketNotifications>0">{{unreadBasketNotifications}}</span>
		<span class="claimUnread" *ngIf="notifications && unreadClaimNotifications>0">{{unreadClaimNotifications}}</span>
		<button class="notifications-button" [matMenuTriggerFor]="notification" mat-button>
			<span class="icon fa fa-bell notification"></span>
		</button>
	</ng-container>
	<mat-menu #notification>
		<div *ngIf="notifications.length > 0">
			<div class="notification-header">
				<div class="text">
					{{'Common.NotificationMenu_Header' | translate}}
				</div>
				<div class="clear-all">
					<div class="pull-right clear-all-link" (click)="onDeleteAllNotifications()">
						{{'Common.NotificationMenu_ClearAll' | translate}}
					</div>
				</div>
			</div>
			<div class="notification-content" *ngFor="let item of notifications">
				<ng-container [ngSwitch]="item.type">
					<tec-basket-notification-txml4 *ngSwitchCase="type.basket" [model]="getObject(item.content)"
						[notification]="item" (onNotificationClicked)="onNotificationClicked($event)"
						(onDeleteNotification)="onDeleteNotification($event)"></tec-basket-notification-txml4>
					<tec-basket-notification-txml5 *ngSwitchCase="type.basketTxml5"
						[model]="getObject(item.content)" [notification]="item"
						(onNotificationClicked)="onNotificationClicked($event)"
						(onDeleteNotification)="onDeleteNotification($event)"></tec-basket-notification-txml5>
					<tec-claim-notification *ngSwitchCase="type.claim" [model]="getObject(item.content)"
						[notification]="item" (onNotificationClicked)="onNotificationClicked($event)"
						(onDeleteNotification)="onDeleteNotification($event)"></tec-claim-notification>
				</ng-container>
			</div>
			</div>
		<div class="empty-notification" *ngIf="(notifications === undefined || notifications==null || notifications.length === 0)">
			{{'Common.NotificationMenu_NoNew'|translate}}
		</div>
	</mat-menu>
</div>