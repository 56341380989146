import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  public get length(): number {
    return localStorage.length;
  }

  public getItem<T>(key: string): T {
    const json = localStorage.getItem(key);
    if (json) {
      return JSON.parse(json) as T;
    }

    return null;
  }

  public setItem(key: string, item: any): void {
    localStorage.setItem(key, JSON.stringify(item));
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public clear(): void {
    localStorage.clear();
  }
}
