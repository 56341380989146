import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { TableModule } from 'primeng/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { BarRatingModule } from 'ngx-bar-rating';

import { TaIconApplicableComponent } from '@shared/components/ta-icon-applicable/ta-icon-applicable.component';
import { TaSpinnerComponent } from '@shared/components/ta-spinner/ta-spinner.component';
import { TaBadgeComponent } from '@shared/components/ta-badge/ta-badge.component';
import { GenericDialogComponent } from '@shared/components/generic-dialog/generic-dialog.component';
import { UtcToLocalTimePipe } from '@shared/pipe/utc-to-local-time.pipe';
import { ExtendDatePipe } from '@shared/pipe/extend-date.pipe';
import { NotificationsService } from '@shared/services/notifications.service';
import { SpinnerService } from '@shared/services/spinner.service';
import { LocaleBasedDateAdapter } from '@shared/adapter/locale-based-date.adapter';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DropdownModule } from 'primeng/dropdown';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { LeafBadgeComponent } from './components/leaf-badge/leaf-badge.component';
import { QuestionMarkComponent } from './components/order-pro-chip/question-mark/question-mark.component';
import { OrderProChipComponent } from './components/order-pro-chip/order-pro-chip.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { ReplacePipe } from './pipe/replace.pipe';
import { TaHeaderComponent } from './components/ta-header/ta-header.component';
import { ValidationReleaseHttpService } from './services/validation-release-http.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    MatDividerModule,
    TableModule,
    ToastrModule.forRoot(),
    MatInputModule,
    MatDatepickerModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatCardModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatTooltipModule,
    MatMenuModule,
    ClipboardModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatSelectModule,
    MatIconModule,
    MatSnackBarModule,
    DropdownModule,
    BarRatingModule,
    PopoverModule.forRoot()
  ],
  declarations: [
    TaIconApplicableComponent,
    TaSpinnerComponent,
    TaBadgeComponent,
    GenericDialogComponent,
    UtcToLocalTimePipe,
    ExtendDatePipe,
    CookieConsentComponent,
    LeafBadgeComponent,
    QuestionMarkComponent,
    OrderProChipComponent,
    SafeHtmlPipe,
    ReplacePipe,
    TaHeaderComponent
  ],
  exports: [
    FormsModule,
    TranslateModule,
    MatButtonModule,
    MatDividerModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatCardModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    MatMenuModule,
    MatSelectModule,
    TableModule,
    TaIconApplicableComponent,
    TaSpinnerComponent,
    ClipboardModule,
    TaBadgeComponent,
    GenericDialogComponent,
    UtcToLocalTimePipe,
    ExtendDatePipe,
    DropdownModule,
    CookieConsentComponent,
    LeafBadgeComponent,
    BarRatingModule,
    QuestionMarkComponent,
    OrderProChipComponent,
    TaHeaderComponent
  ],
  providers: [
    ToastrService,
    NotificationsService,
    SpinnerService,
    ExtendDatePipe,
    LocaleBasedDateAdapter,
    ValidationReleaseHttpService,
    { provide: DateAdapter, useClass: LocaleBasedDateAdapter }
  ]
})
export class SharedModule { }
