import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Actions, Select, Store, ofActionDispatched } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SetCookieConsent } from '@app/vc-tool/state/actions/cookie-consent-set.actions';
import { CookieConsentConst } from './cookie-consent.const';
import { AppSetting, APP_SETTING } from '@app/core/app-settings';
import { OrganisationState } from '@app/auth/state/organisation.state';
import { LanguageChangeAction } from '@auth/state/actions/language/language-change.actions';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tec-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1
      })),
      state('closed', style({
        height: 0,
        bottom: 0,
        opacity: 0.5
      })),
      transition('open => closed', [
        animate('0.2s ease-out')
      ]),
      transition('closed => open', [
        animate('0.2s ease-in')
      ])
    ])
  ]
})
export class CookieConsentComponent implements OnInit, OnDestroy {
  @Select(OrganisationState.cookieConsent)
  public cookieConsent$: Observable<string>;
  public privacyUrl: { language: string; link: string };
  public cookieConsentConst = CookieConsentConst;
  public lgpdDownloadHyperLink: string;

  private unsubscribe: Subject<void> = new Subject();

  constructor(private store: Store,
              private actions$: Actions,
              private translateService: TranslateService,
              @Inject(APP_SETTING) private appSettings: AppSetting) {
  }

  ngOnInit(): void {
    this.setPrivacyUrl();
    this.setLGPDDownloadHyperLink();
    this.actions$.pipe(ofActionDispatched(LanguageChangeAction), takeUntil(this.unsubscribe)).subscribe(() => {
      this.setPrivacyUrl();
      this.setLGPDDownloadHyperLink();
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public setCookieConsent(consent: string): void {
    this.store.dispatch(new SetCookieConsent(consent));
  }

  private setPrivacyUrl(): void {
    const privacyUrl = this.appSettings.dataPrivacyCookiesUrls.find(url =>
      url.language === this.translateService.currentLang);
    this.privacyUrl = privacyUrl ?? this.appSettings.dataPrivacyCookiesUrls.find(url => url.language === 'en');
  }

  private setLGPDDownloadHyperLink(){
    this.translateService.get('CookieConsent.DownloadFile').subscribe(result => {
      this.lgpdDownloadHyperLink = `<a href="${this.appSettings.lgpdDownloadLink}" target="_blank">${result}</a>`;
    });
  }
}
