import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '@environment';
import { TecWebUrlService } from 'teccom-navigation';

import { AuthenticationService } from '@core/services/authentication.service';
import { TranslationsService } from '@core/translations/translations.service';
import { getCurrentDomain, joinSubdomainAndDomain } from '@core/utils/location.utils';
import { UserModel } from '@auth/models/user/user.model';
import { QuickstartLanguage } from '@auth/models/quickstart-language.enum';
import { ManualLanguage } from '@auth/models/manual-language.enum.';
import { DiscardChangesService } from '@auth/services/discard-changes.service';
import { MenuService } from '@app/auth/services/menu.service';
import { TecComPortalResizeAction } from '@auth/state/actions/teccom-menu-actions';
import { UiLanguageService } from '@core/services/ui-language.service';
import { RetrieveOrganisation } from '@auth/state/actions/organization/retrieve-organisation.action';
import { APP_SETTING, AppSetting } from '@core/app-settings';


@Component({
  selector: 'tec-header-info-auth',
  templateUrl: './header-info-auth.component.html',
  styleUrls: ['./header-info-auth.component.scss']
})
export class HeaderInfoAuthComponent implements OnInit, OnDestroy {
  @Input() userData: UserModel;
  @Output() languageChanged = new EventEmitter<string>();

  public isDirty = false;
  public tecWebHostName: string;
  public manualLanguage = 'en';
  public quickStartLanguage = 'en';
  public isMobileDevice = false;

  private supportedQuickstartLanguage = QuickstartLanguage;
  private supportedManualLanguage = ManualLanguage;
  private currentRouteSub: Subscription;
  private currentRoute: string;
  private unsubscribe: Subject<void> = new Subject();

  constructor(private discardChangesService: DiscardChangesService,
    private confirmationService: ConfirmationService,
    private translationsService: TranslationsService,
    private uiLangService: UiLanguageService,
    private authService: AuthenticationService,
    private actions$: Actions,
    private menuService: MenuService,
    private urlService: TecWebUrlService,
    private store: Store,
    private router: Router,
    @Inject(APP_SETTING) private appSettings: AppSetting) {
  }

  ngOnInit(): void {
    this.store.dispatch(new RetrieveOrganisation());
    this.tecWebHostName = joinSubdomainAndDomain(environment.moduleSubdomains.tecweb, getCurrentDomain());
    this.discardChangesService.dirtyStateChange.subscribe(isDirty => this.isDirty = isDirty);
    this.setManualAndQuickStartLanguage();
    this.setCurrentRoute();
    this.setIsMobileDevice();
  }

  ngOnDestroy(): void {
    if (this.currentRouteSub) {
      this.currentRouteSub.unsubscribe();
    }
  }

  get showOrganizationSelection(): boolean {
    return !this.appSettings.hideBuyerPartyRoutes.some(route => this.currentRoute.includes(route));
  }

  onLogout(event: any): void {
    this.authService.logout(event, true);
  }

  onUserProfileClick(event: Event): void {
    this.goToHeaderLink('/user/profile',
      this.translationsService.instant('DiscardChanges.Title'),
      this.translationsService.instant('DiscardChanges.Content'),
      false);

    event.preventDefault();
  }

  menuOpened(menuItem: string): void {
    const element = document.getElementById(menuItem);
    if (element.classList.contains('fa-caret-down')) {
      element.classList.replace('fa-caret-down', 'fa-caret-up');
    }
  }

  menuClosed(menuItem: string): void {
    const element = document.getElementById(menuItem);
    if (element.classList.contains('fa-caret-up')) {
      element.classList.replace('fa-caret-up', 'fa-caret-down');
    }
  }

  onUpgradeClick(event: Event): void {
    this.goToHeaderLink(this.appSettings.subscriptionsRoute,
      this.translationsService.instant('DiscardChanges.DiscardChanges_Title'),
      this.translationsService.instant('DiscardChanges.DiscardChanges_Content'),
      false);

    event.preventDefault();
  }

  private goToHeaderLink(url: string, title: string, msg: string, isLogOut: boolean): void {
    if (!this.discardChangesService.isDirty() && !isLogOut) {
      window.dispatchEvent(new Event('gototecwebpage'));
      this.urlService.navigate(url, environment.moduleSubdomains.tecweb);

      return;
    }

    this.confirmationService.confirm({
      key: 'header-info-confirmation',
      header: title,
      message: msg,
      accept: () => {
        window.dispatchEvent(new Event('gototecwebpage'));
        this.discardChangesService.clearAllDirty();
        this.urlService.navigate(url, environment.moduleSubdomains.tecweb);
      }
    });
  }

  private setManualAndQuickStartLanguage(): void {
    const lang = this.uiLangService.activeLanguage;

    this.supportedManualLanguage[lang] !== null ? this.manualLanguage = lang : this.manualLanguage = 'en';
    this.supportedQuickstartLanguage[lang] !== null ? this.quickStartLanguage = lang : this.quickStartLanguage = 'en';
  }

  private setIsMobileDevice(): void {
    this.isMobileDevice = this.menuService.checkMobileDevice();
    this.actions$.pipe(ofActionDispatched(TecComPortalResizeAction), takeUntil(this.unsubscribe)).subscribe(() => {
      this.isMobileDevice = this.menuService.checkMobileDevice();
    });
  }

  private setCurrentRoute(): void {
    this.currentRoute = this.router.url;
    this.currentRouteSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.currentRoute = event.url);
  }
}
