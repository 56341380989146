import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, Inject, ViewChild, ViewContainerRef } from '@angular/core';

import { GenericDialogDataModel } from '@shared/components/generic-dialog/models/generic-dialog-data.model';

@Component({
  selector: 'generic-dialog',
  templateUrl: './generic-dialog.component.html'
})
export class GenericDialogComponent implements AfterViewInit {
  @ViewChild('bodyRef', { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(
    private resolver: ComponentFactoryResolver,
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: GenericDialogDataModel) {
  }

  ngAfterViewInit(): void {
    this.container.clear();
    const factory = this.resolver.resolveComponentFactory(this.data.body);
    const component = factory.create(this.container.injector);
    this.data.bodyInputs?.forEach(s => {
      component.instance[s.key] = s.value;
    });
    this.container.insert(component.hostView);
    this.cdRef.detectChanges();
  }
}
