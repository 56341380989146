import { BehaviorSubject, Observable } from 'rxjs';

export class SpinnerService {
  private isLoading$ = new BehaviorSubject<boolean>(false);
  private countInvokes = 0;

  get(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }

  open(): void {
    this.isLoading$.next(true);
    this.countInvokes++;
  }

  reset(): void {
    this.countInvokes = 0;
    this.isLoading$.next(false);
  }

  close(): void {
    this.countInvokes--;
    if (this.countInvokes === 0) {
      this.isLoading$.next(false);
    }
  }
}
