import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class DiscardChangesService {
  dirtyStateChange: EventEmitter<boolean> = new EventEmitter();
  private _isDirty: any = { };

  isDirty(): boolean {
    let isDirty = false;
    Object.keys(this._isDirty).forEach(
      (key: string) => {
        if (this._isDirty[key]) {
          isDirty = true;
        }
      },
      this);

    return isDirty;
  }

  clearAllDirty(): void {
    this._isDirty = { };
    this.dirtyStateChange.emit(false);
  }

  getDirty(key: string): boolean {
    return this._isDirty[key];
  }

  changeDirty(key: string, isDirty: boolean): void {
    this._isDirty[key] = isDirty;
    if (isDirty) {
      this.dirtyStateChange.emit(true);

      return;
    }

    this.dirtyStateChange.emit(this.isDirty());
  }
}
