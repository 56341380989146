import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { VcToolRoutingConst } from '@vc-tool/vc-tool-routing.const';

@Injectable()
export class ActiveSubscriptionInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError(error => {
          if (error.status === 402) {
            this.router.navigate(
              [`${VcToolRoutingConst.VcToolRoot}/${VcToolRoutingConst.SubscriptionError}`]);

            return next.handle(request);
          }

          return observableThrowError(error);
        }));
  }
}
