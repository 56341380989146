import { NavigationExtras } from '@angular/router';
import { getCurrentDomain, joinSubdomainAndDomain } from '@core/utils/location.utils';

export const navigate = (route: string, targetModule: string, extras?: NavigationExtras) => {
  let queryParams = '';
  if (extras && extras.queryParams) {
    queryParams = `/?${Object.keys(extras.queryParams).map(key => `${key}=${extras.queryParams[key]}`).join('&')}`;
  }
  window.location.assign(`${joinSubdomainAndDomain(targetModule, getCurrentDomain())}/newapp${route}${queryParams}`);
};
