import { UiLanguageService } from '@core/services/ui-language.service';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { OKTA_CONFIG, OktaAuthModule, OKTA_AUTH } from '@okta/okta-angular';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ENVIRONMENT } from 'teccom-navigation';

import { TranslationsFactory } from '@core/factories/translations.factory';
import { JwtOptionsFactory } from '@core/factories/jwt-options.factory';
import { TaHttpClient } from '@core/http/ta-http-client';
import { AuthenticationService } from '@core/services/authentication.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { environment } from '@environment';
import { AuthModule } from '@auth/auth.module';
import { CanActivateAdminGuard } from '@core/guards/can-activate-admin.guard';
import { UserDetailsGuard } from '@core/guards/user-details.guard';
import { AuthState, OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { AppSetting, APP_SETTING } from '@core/app-settings';
import { HttpInterceptorProviders } from './constants/http-interceptor-providers.const';
import { TranslationsService } from './translations/translations.service';
import { CanActivateTecComAdminGuard } from './guards/can-activate-teccom-admin.guard';

const oktaConfig = new OktaAuth({
  clientId: environment.oktaOptions.clientId,
  issuer: environment.oktaOptions.issuerUri,
  redirectUri: environment.oktaOptions.redirectUri,
  postLogoutRedirectUri: environment.oktaOptions.postLogoutRedirectUri,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  tokenManager: {
    autoRenew: true
  },
  ignoreLifetime: true,
  transformAuthState: async (oktaAuth: OktaAuth, authState: AuthState) => {
    if (!authState.isAuthenticated) {
      return authState;
    }
    const doesOktaSessionExist = await oktaAuth.session.exists();
    if (!doesOktaSessionExist) {
      authState.isAuthenticated = false;
      await oktaAuth.signOut();
    }

    return authState;
  }
} as OktaAuthOptions);

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationsFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: JwtOptionsFactory,
        deps: [OKTA_AUTH]
      }
    }),
    OktaAuthModule,
    AuthModule
  ],
  providers: [
    TaHttpClient,
    CookieService,
    AuthenticationService,
    LocalStorageService,
    TranslationsService,
    HttpInterceptorProviders,
    {
      provide: OKTA_CONFIG,
      useValue: { oktaAuth: oktaConfig }
    },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: APP_SETTING, useValue: AppSetting },
    CanActivateAdminGuard,
    UserDetailsGuard,
    UiLanguageService,
    CanActivateTecComAdminGuard
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('CoreModule has been already created in AppModule.');
    }
  }
}
