import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'question-mark',
  templateUrl: './question-mark.component.html',
  styleUrls: ['./question-mark.component.scss']
})
export class QuestionMarkComponent {
  @Output() onClickOrderProTooltip = new EventEmitter();
}
