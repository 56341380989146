import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';

import { LocaleSettingsService } from '@core/services/locale-settings.service';
import { ExtendDatePipe } from '@shared/pipe/extend-date.pipe';

@Injectable()
export class LocaleBasedDateAdapter extends MomentDateAdapter {
  constructor(
    private localeSettingsService: LocaleSettingsService,
    private datePipe: ExtendDatePipe) {
    super('');
  }

  parse(value: any, parseFormat: string | string[]): moment.Moment | null {
    if (parseFormat === 'l') {
      return moment(value, this.localeSettingsService.dateFormat.toUpperCase());
    }

    return super.parse(value, parseFormat);
  }

  format(date: moment.Moment, displayFormat: string): string {
    if (displayFormat === 'l') {
      return this.datePipe.transform(date.format(), this.localeSettingsService.dateFormat);
    }

    return date.format(displayFormat);
  }
}
