import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeBG from '@angular/common/locales/bg';
import localeCS from '@angular/common/locales/cs';
import localeDA from '@angular/common/locales/da';
import localeDE from '@angular/common/locales/de';
import localeDEAT from '@angular/common/locales/de-AT';
import localeDELI from '@angular/common/locales/de-LI';
import localeDELU from '@angular/common/locales/de-LU';
import localeDECH from '@angular/common/locales/de-CH';
import localeEL from '@angular/common/locales/el';
import localeENGB from '@angular/common/locales/en-GB';
import localeENIE from '@angular/common/locales/en-IE';
import localeES from '@angular/common/locales/es';
import localeFI from '@angular/common/locales/fi';
import localeFR from '@angular/common/locales/fr';
import localeFRBE from '@angular/common/locales/fr-BE';
import localeFRCA from '@angular/common/locales/fr-CA';
import localeFRLU from '@angular/common/locales/fr-LU';
import localeFRCH from '@angular/common/locales/fr-CH';
import localeHR from '@angular/common/locales/hr';
import localeHRBA from '@angular/common/locales/hr-BA';
import localeHU from '@angular/common/locales/hu';
import localeIT from '@angular/common/locales/it';
import localeITCH from '@angular/common/locales/it-CH';
import localeNL from '@angular/common/locales/nl';
import localeNLBE from '@angular/common/locales/nl-BE';
import localeNB from '@angular/common/locales/nb';
import localeNN from '@angular/common/locales/nn';
import localePL from '@angular/common/locales/pl';
import localePT from '@angular/common/locales/pt';
import localeRO from '@angular/common/locales/ro';
import localeRU from '@angular/common/locales/ru';
import localeSE from '@angular/common/locales/se';
import localeSK from '@angular/common/locales/sk';
import localeSRCyrl from '@angular/common/locales/sr-Cyrl';
import localeSRLatn from '@angular/common/locales/sr-Latn';
import localeSV from '@angular/common/locales/sv';
import localeSVFI from '@angular/common/locales/sv-FI';
import localeTR from '@angular/common/locales/tr';
import localeZH from '@angular/common/locales/zh';
import localeZHHans from '@angular/common/locales/zh-Hans';
import localeZHHant from '@angular/common/locales/zh-Hant';
import localeZHHansSG from '@angular/common/locales/zh-Hans-SG';
import localeZHHantHK from '@angular/common/locales/zh-Hant-HK';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { availableLanguages, ENGLISH_KEY } from '@core/translations/language.const';
import { getCurrentDomain } from '@core/utils/location.utils';

@Injectable() export class TranslationsService {
  private readonly lang = 'Language';

  constructor(
    private ngxTranslateService: TranslateService,
    private cookieService: CookieService) { }

  applyLanguage(): Observable<string> {
    const languageFromStorage = this.getLang();

    const currentLang = availableLanguages.indexOf(languageFromStorage) >= 0
      ? languageFromStorage
      : ENGLISH_KEY;

    this.ngxTranslateService.setDefaultLang(ENGLISH_KEY);
    this.ngxTranslateService.addLangs(availableLanguages);

    return this.useLanguage(currentLang);
  }

  setLang(languageCode: string): void {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);

    this.cookieService.set(this.lang, languageCode, expires, '/', getCurrentDomain());
  }

  getLang(): string {
    let activeLanguage = this.cookieService.get(this.lang);

    if (!activeLanguage) {
      const navigatorLanguage = window.navigator.language;
      activeLanguage = navigatorLanguage?.substring(0, 2);
    }

    return activeLanguage || ENGLISH_KEY;
  }

  registerLocalesData(): void {
    registerLocaleData(localeBG);
    registerLocaleData(localeCS);
    registerLocaleData(localeDA);
    registerLocaleData(localeDE);
    registerLocaleData(localeDEAT);
    registerLocaleData(localeDELI);
    registerLocaleData(localeDELU);
    registerLocaleData(localeDECH);
    registerLocaleData(localeEL);
    registerLocaleData(localeENGB);
    registerLocaleData(localeENIE);
    registerLocaleData(localeES);
    registerLocaleData(localeFI);
    registerLocaleData(localeFR);
    registerLocaleData(localeFRBE);
    registerLocaleData(localeFRCA);
    registerLocaleData(localeFRLU);
    registerLocaleData(localeFRCH);
    registerLocaleData(localeHR);
    registerLocaleData(localeHRBA);
    registerLocaleData(localeHU);
    registerLocaleData(localeIT);
    registerLocaleData(localeITCH);
    registerLocaleData(localeNL);
    registerLocaleData(localeNLBE);
    registerLocaleData(localeNB);
    registerLocaleData(localeNN);
    registerLocaleData(localePL);
    registerLocaleData(localePT);
    registerLocaleData(localeRO);
    registerLocaleData(localeRU);
    registerLocaleData(localeSE);
    registerLocaleData(localeSK);
    registerLocaleData(localeSRCyrl, 'sr-Cyrl-CS');
    registerLocaleData(localeSRLatn, 'sr-Latn-CS');
    registerLocaleData(localeSK);
    registerLocaleData(localeSV);
    registerLocaleData(localeSVFI);
    registerLocaleData(localeTR);
    registerLocaleData(localeZH);
    registerLocaleData(localeZHHans, 'zh-CN');
    registerLocaleData(localeZHHant, 'zh-TW');
    registerLocaleData(localeZHHantHK, 'zh-HK');
    registerLocaleData(localeZHHansSG, 'zh-SG');
  }

  instant(key: string | string[], interpolateParams?: unknown): any {
    return this.ngxTranslateService.instant(key, interpolateParams);
  }

  get(key: string | string[], interpolateParams?: unknown): Observable<string | any> {
    return this.ngxTranslateService.get(key, interpolateParams);
  }

  stream(key: string | string[], interpolateParams?: unknown): Observable<string | any> {
    return this.ngxTranslateService.stream(key, interpolateParams);
  }

  private useLanguage(code: string): Observable<string> {
    this.setLang(code);

    return this.ngxTranslateService.use(code).pipe(map(() => code));
  }
}
