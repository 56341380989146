import { Injectable, EventEmitter } from '@angular/core';

import { ClaimNotificationModel } from '@auth/components/notification-menu/models/claim-notification.model';
import { NotificationModel } from '@auth/components/notification-menu/models/notification.model';


@Injectable()
export class ClaimNotificationLoaderService {
  onLoadClaim: EventEmitter<ClaimNotificationModel> = new EventEmitter();
  onDeleteNotification: EventEmitter<NotificationModel> = new EventEmitter();
  onSetActiveClaim: EventEmitter<ClaimNotificationModel> = new EventEmitter();

  loadClaim(claimNotification: ClaimNotificationModel): void {
    this.onLoadClaim.emit(claimNotification);
  }

  setActiveClaim(claimNotification: ClaimNotificationModel): void {
    this.onSetActiveClaim.emit(claimNotification);
  }
}
