export const environment = {
  production: true,
  whitelistedDomains: ['om-masterdataservice.tecalliance.net'],
  tecWebSiteUrl: 'https://teccom.staging.tecalliance.cloud/newapp',
  masterDataServiceUrl: 'https://om-masterdataservice.tecalliance.net/Api',
  enableOrderManagerBeta: false,
  signalRUrl: 'https://tecnotificationservice-staging.azurewebsites.net',
  enableReturnsBeta: false,
  defaultRoute: '/home/dashboard',
  currentModule: 'https://validationcertification.stage.tecalliance.cloud/',
  reportAnIssueUrl: 'https://ordersupportweb-staging-frontend.azurewebsites.net',
  statusPageUrl: 'https://status.tecalliance.net',
  orderManagerWikiUrl: 'https://help-ordermanagerportal.tecalliance.net',
  knowledgeDatabaseApiUrl: 'https://keyw8i9r9b.execute-api.eu-west-1.amazonaws.com/staging',
  moduleSubdomains: {
    returns: 'https://returns.',
    tecweb: 'https://teccom.',
    connect: 'https://connectweb.',
    vcTool: 'https://validationcertification.',
    dashboard: 'https://dashboard.',
    mfe: 'https://teccom-portal.'
  },
  oktaOptions: {
    clientId: '0oa16aalpeptgwJ0Z417',
    issuerUri: 'https://login.tecalliance.net/oauth2/default',
    redirectUri: `${window.location.origin}/callback`,
    postLogoutRedirectUri: 'https://teccom.staging.tecalliance.cloud/newapp/auth/welcome'
  },
  showTxml5: true
};
