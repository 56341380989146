<div class="p-2">
    <div data-test-id="dialog-title" class="align-self-center tp-headline">
        {{data.title|translate}}
    </div>
    <ng-template data-test-id="dialog-body" #bodyRef></ng-template>
    <mat-dialog-actions class="d-flex justify-content-end">
        <button data-test-id="dialog-submit-btn" [mat-dialog-close]="true" class="mr-3" mat-flat-button color="primary">
            {{data.submitButtonText|translate}}
        </button>
        <button data-test-id="dialog-cancel-btn" class="text-primary" mat-stroked-button [mat-dialog-close]="false">{{data.cancelButtonText|translate}}</button>
    </mat-dialog-actions>
</div>