import { EventEmitter, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { getCurrentDomain } from '@core/utils/location.utils';
import { OrganisationMappingModel } from '@auth/models/user/organisation-mapping.model';
import { TecWebUrlService } from 'teccom-navigation';

@Injectable({ providedIn: 'root' })
export class CurrentOrganizationService {
  readonly selectedOrganizationTecIdKey = 'selectedOrgTecId';
  readonly currentOrganizationIndexKey = 'currentSalesOrg';
  readonly focusedOrderProKey = 'focusedOrderProOrganizations';

  public currentOrganization: OrganisationMappingModel;
  public currentOrganizationChange: EventEmitter<string> = new EventEmitter();
  private selectedOrganizationTecId: string;

  constructor(private cookieService: CookieService, private urlService: TecWebUrlService) { }

  public getSelectedOrganizationTecId(): string {
    if (!this.selectedOrganizationTecId) {
      this.selectedOrganizationTecId = this.cookieService.get(this.selectedOrganizationTecIdKey);
    }

    return this.selectedOrganizationTecId;
  }

  public getCurrentOrganization(): OrganisationMappingModel {
    return this.currentOrganization;
  }

  public setCurrentOrganization(value: OrganisationMappingModel): void {
    this.currentOrganization = value;
    this.setCurrentOrganizationTecId(this.currentOrganization.tecComId);
    this.setLegacyIndex(value.val.toString());
  }

  public isSupplier(): boolean {
    return this.currentOrganization.organisationRole === 1;
  }

  public isOrgFocusedOrderPro(): boolean {
    let focusedOrderProOrganizations = this.cookieService.get(this.focusedOrderProKey);
    if (focusedOrderProOrganizations && focusedOrderProOrganizations.includes(this.selectedOrganizationTecId)) {
      return false;
    }
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    focusedOrderProOrganizations = !focusedOrderProOrganizations
      ? `${this.selectedOrganizationTecId  };`
      : `${focusedOrderProOrganizations + this.selectedOrganizationTecId  };`;
    this.cookieService.set(this.focusedOrderProKey, focusedOrderProOrganizations, expires, '/', this.urlService.getCurrentDomain());

    return true;
  }

  private setCurrentOrganizationTecId(tecId: string): void {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);

    this.selectedOrganizationTecId = tecId;
    this.cookieService.set(
      this.selectedOrganizationTecIdKey,
      this.selectedOrganizationTecId, expires,
      '/',
      getCurrentDomain());
    this.currentOrganizationChange.emit(tecId);
  }

  private setLegacyIndex(value: string): void {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);

    this.cookieService.set(this.currentOrganizationIndexKey, value, expires, '/', getCurrentDomain());
  }
}
