import { Inject, Injectable } from '@angular/core';
import { UiLanguageService } from '@core/services/ui-language.service';
import { CurrentOrganizationService } from '@auth/services/current-organization.service';
import { UserService } from '@auth/services/user.service';
import { environment } from '@environment';
import { AppSetting, APP_SETTING } from '@app/core/app-settings';

@Injectable({ providedIn: 'root' })
export class SupportService {
  constructor(
    private languageService: UiLanguageService,
    private currentOrgService: CurrentOrganizationService,
    private userService: UserService,
    @Inject(APP_SETTING) private appSettings: AppSetting) { }

  reportAnIssue(): void {
    // Collect relevant information
    const supportParams = new URLSearchParams();
    supportParams.set('firstname', this.userService.currentUser.firstName);
    supportParams.set('lastname', this.userService.currentUser.lastName);
    supportParams.set('email', this.userService.currentUser.email);
    supportParams.set('orgtecid', this.currentOrgService.currentOrganization.tecComId);
    supportParams.set('orgname', this.currentOrgService.currentOrganization.organisationName);
    supportParams.set('product', 'omp');
    supportParams.set('language', this.languageService.activeLanguage);

    if (this.userService.currentUser.phone) {
      supportParams.set('phone', this.userService.currentUser.phone);
    }

    window.open(`${environment.reportAnIssueUrl}?${supportParams.toString()}`);
  }

  getReportAnIssueLink(): string {
    if (this.userService.currentUser === null && this.currentOrgService.currentOrganization === null) {
      return environment.reportAnIssueUrl;
    }

    // Collect relevant information
    const supportParams = new URLSearchParams();
    supportParams.set('firstname', this.userService.currentUser.firstName);
    supportParams.set('lastname', this.userService.currentUser.lastName);
    supportParams.set('email', this.userService.currentUser.email);
    supportParams.set('orgtecid', this.currentOrgService.currentOrganization.tecComId);
    supportParams.set('orgname', this.currentOrgService.currentOrganization.organisationName);
    supportParams.set('product', 'omp');
    supportParams.set('language', this.languageService.activeLanguage);

    if (this.userService.currentUser.phone) {
      supportParams.set('phone', this.userService.currentUser.phone);
    }

    return `${environment.reportAnIssueUrl}?${supportParams.toString()}`;
  }

  getWikiUrl(pageRelativeUrl: string, specificLanguage?: string): string {
    const activeLanguage = specificLanguage || this.languageService.activeLanguage;
    const language = this.appSettings.wikiSupportedLanguages.find(l => l === activeLanguage) ||
                     this.appSettings.wikiDefaultLanguage;

    return `${this.appSettings.wikiRootUrl}/${language}/${pageRelativeUrl}`;
  }
}
