import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { IFooterLink, IMenuLink, IMenuNode } from 'teccom-menu';
import { TecWebUrlService } from 'teccom-navigation';
import { environment } from '@environment';
import * as menuConfig from 'src/assets/menu.json';
import { SupportService } from '@shared/services/support.service';
import { APP_SETTING, AppSetting } from '@app/core/app-settings';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class MenuService {
  public static MenuResourceKeys = {
    MainNav: 'mainNav',
    Footer: 'footer',
    MobileNav: 'mobileNav'
  };
  private readonly _maxMobileWidth = 600;

  constructor(
    private translateService: TranslateService,
    private urlService: TecWebUrlService,
    private _deviceService: DeviceDetectorService,
    @Inject(APP_SETTING) private appSettings: AppSetting,
    private supportService: SupportService) {
  }

  buildMenu(menuConfigKey: string): any {
    const menuSource = (menuConfig as any).default[menuConfigKey];
    const menu = cloneDeep(menuSource);
    menu.nodes.forEach((node: IMenuNode | IFooterLink) => {
      const subLinks = (node as IMenuNode).subLinks || [];
      this.generateNodeLinks(node);
      const translationKeys = [node.name, ...subLinks.map(e => e.name)];
      this.translateService.get(translationKeys).subscribe(translations => {
        node.name = node.name === 'Footer.Footer_Brand'
          ? `© ${new Date().getFullYear()} ${translations[node.name]}` : translations[node.name];
        subLinks.forEach(sublink => {
          sublink.name = translations[sublink.name];
        });
      });
    });

    if (menu.mobileFooterNode) {
      for (const nodeKey in menu.mobileFooterNode) {
        if (menu.mobileFooterNode[nodeKey].name) {
          menu.mobileFooterNode[nodeKey].name = this.translateService.instant(menu.mobileFooterNode[nodeKey].name);
        }
      }
    }

    const extraTranslations = Object.keys(menu.translations).map(e => menu.translations[e]);
    if (extraTranslations?.length > 0) {
      this.translateService.get(extraTranslations).subscribe(translations => {
        Object.keys(menu.translations).map(e => menu.translations[e] = translations[menu.translations[e]]);
      });
    }

    return menu;
  }

  checkMobileDevice(): boolean {
    return this._deviceService.isMobile() || window.innerWidth < this._maxMobileWidth;
  }

  private generateNodeLinks(node: IMenuNode | IFooterLink): void {
    const menuNode = node as IMenuNode;
    if (menuNode.subLinks?.length) {
      menuNode.subLinks.forEach(s => this.generateLink(s));

      return;
    }
    this.generateLink(node);
  }

  private generateLink(link: IMenuLink | IFooterLink): void {
    if (link.isEnvironmentConfig) {
      switch (link.link) {
      case 'reportAnIssueUrl':
        link.link = this.supportService.getReportAnIssueLink();
        break;
      case 'orderManagerWikiUrl':
        link.link = this.supportService.getWikiUrl(this.appSettings.wikiHomePageUrl);
        break;
      default:
        link.link = environment[link.link];
        break;
      }

      return;
    }

    if (link.isAppSetting) {
      link.link = this.appSettings[link.link];
    }

    switch ((link as IMenuLink).module) {
    case 'returns':
      link.link = this.urlService.getModuleHostname(environment.moduleSubdomains.returns) + link.link;
      break;
    case 'tecweb':
      link.link = this.urlService.getModuleHostname(environment.moduleSubdomains.tecweb) + link.link;
      break;
    case 'connect':
      link.link = this.urlService.getModuleHostname(environment.moduleSubdomains.connect) + link.link;
      break;
    case 'vc-tool':
      link.link = this.urlService.getModuleHostname(environment.moduleSubdomains.vcTool) + link.link;
      break;
    case 'dashboard':
      link.link = this.urlService.getModuleHostname(environment.moduleSubdomains.dashboard) + link.link;
      break;
    case 'mfe':
      link.link = this.urlService.getModuleHostname(environment.moduleSubdomains.mfe) + link.link;
      break;
    default:
      break;
    }
  }
}
