import { Component, Input } from '@angular/core';

@Component({
  selector: 'leaf-badge',
  templateUrl: './leaf-badge.component.html',
  styleUrls: ['./leaf-badge.component.scss']
})
export class LeafBadgeComponent {
  @Input() color: string;
  @Input() value: string;
}
