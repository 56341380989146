import { Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { BroadcastEventListener, ConnectionStatus, ISignalRConnection, SignalR } from 'ng2-signalr';
import { environment } from '@environment';

import { TranslationsService } from '@core/translations/translations.service';
import { NotificationsService } from '@shared/services/notifications.service';
import { UserService } from '@auth/services/user.service';
import { NotificationService } from '@auth/components/notification-menu/services/notification.service';
import { CurrentOrganizationService } from '@auth/services/current-organization.service';
import { NotificationType } from '@auth/components/notification-menu/models/notification-type.enum';
import { NotificationModel } from '@auth/components/notification-menu/models/notification.model';
import { VisibilityManagerService } from '@auth/components/notification-menu/services/visibility-manager.service';
import { BasketNotificationLoaderTxml4Service } from '@auth/components/notification-menu/services/basket-notification-loader-txml4.service';
import { BasketNotificationLoaderTxml5Service } from '@auth/components/notification-menu/services/basket-notification-loader-txml5.service';
import { ClaimNotificationLoaderService } from '@auth/components/notification-menu/services/claim-notification-loader.service';

@Component({
  selector: 'tec-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss']
})
export class NotificationMenuComponent implements OnInit, OnDestroy {
  public notifications: NotificationModel[] = [];
  public unreadBasketNotifications = 0;
  public unreadClaimNotifications = 0;
  public reconnectionCount = 0;
  public expanded = false;
  public type = NotificationType;
  public reloadNotification: MatSnackBarRef<TextOnlySnackBar>;

  private translations: string;
  private connection: ISignalRConnection;
  private connectionStatusSubscription: any;
  private messageSubscription: any;
  private deleteSubscription: any;
  private notificationSubscription: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private translationsService: TranslationsService,
    private signalR: SignalR,
    private currentOrganizationService: CurrentOrganizationService,
    private userService: UserService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private notifyService: NotificationsService,
    private elementRef: ElementRef,
    public visibilityManagerService: VisibilityManagerService,
    private basketLoaderTxml4Service: BasketNotificationLoaderTxml4Service,
    private basketLoaderTxml5Service: BasketNotificationLoaderTxml5Service,
    private claimLoaderService: ClaimNotificationLoaderService,
    private ngZone: NgZone,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.translationsService.get([
      'NotificationMenu.ConfirmClearAllTitle',
      'NotificationMenu.ConfirmClearAllMsg',
      'NotificationMenu.RemovedTitle'
    ]).subscribe(result => {
      this.translations = result;
    });
    this.handleNotifications();
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.releaseConnections();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
  }

  onCurrentOrganizationChange(): void {
    this.releaseConnections();
    this.unreadBasketNotifications = 0;
    this.unreadClaimNotifications = 0;
    this.notifications = [];
    this.getNotifications();
  }

  getObject(content: string): any {
    return JSON.parse(content);
  }

  @HostListener('document:click', ['$event']) onClickAway(event: Event): void {
    if (event && !this.elementRef.nativeElement.contains(event.target) && this.expanded) {
      this.toggleExpand();
    }
  }

  onNotificationClicked(item: NotificationModel): void {
    this.expanded = false;
    if (item.type === NotificationType.basket) {
      this.unreadBasketNotifications--;
    }
  }

  onDeleteNotification(item: NotificationModel): void {
    this.deleteNotification(item);
  }

  onDeleteAllNotifications(): void {
    this.expanded = false;
    this.confirmationService.confirm({
      key: 'warning-confirmation',
      header: this.translations['NotificationMenu.ConfirmClearAllTitle'],
      message: this.translations['NotificationMenu.ConfirmClearAllMsg'],
      accept: () => {
        this.notificationService.deleteAllNotifications()
          .subscribe(data => {
            let message = this.translationsService.instant('NotificationMenu.NoneRemovedMsg');
            if (data > 0) {
              message = this.translationsService.instant('NotificationMenu.RemovedSuccessMsg').replace('{0}', data);
            }
            this.notifyService.success(
              message,
              this.translations['NotificationMenu.RemovedTitle']);
          });
      }
    });

    this.updateCounter();
  }

  private deleteNotification(item: NotificationModel): void {
    this.notificationService.deleteNotificationById(item.id).subscribe();

    this.updateCounter();
  }

  private handleNotifications(): void {
    this.getNotifications();

    this.subscriptions.push(this.currentOrganizationService.currentOrganizationChange
      .subscribe(() => this.onCurrentOrganizationChange()));
  }

  private releaseConnections(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
    if (this.connectionStatusSubscription) {
      this.connectionStatusSubscription.unsubscribe();
    }
    if (this.connection) {
      this.connection.stop();
    }
  }

  private addOrUpdateMessage(notification: NotificationModel): void {
    const localNotification = this.notifications.find(item => item.id === notification.id);

    if (localNotification) {
      localNotification.content = notification.content;
      localNotification.type = notification.type;
    } else {
      this.notifications.splice(0, 0, notification);
    }

    switch (notification.type) {
    case NotificationType.basket:
      this.basketLoaderTxml4Service.loadBasket(this.getObject(notification.content));
      break;
    case NotificationType.claim:
      this.claimLoaderService.loadClaim(this.getObject(notification.content));
      break;
    case NotificationType.basketTxml5:
      this.basketLoaderTxml5Service.loadBasket(this.getObject(notification.content));
      break;
    default:
      break;
    }
    this.updateCounter();
  }

  private updateCounter(): void {
    this.unreadBasketNotifications = 0;
    this.unreadClaimNotifications = 0;
    this.notifications.forEach(n => {
      if (n.type === NotificationType.basket || n.type === NotificationType.basketTxml5) {
        this.unreadBasketNotifications++;
      }
      if (n.type === NotificationType.claim) {
        this.unreadClaimNotifications++;
      }
    });
  }

  private listenForMessages(): void {
    const onReceiveMessage = new BroadcastEventListener<NotificationModel>('SendMessage');
    const onDeleteNotification = new BroadcastEventListener<number>('DeleteNotification');

    this.connection.listen(onReceiveMessage);
    this.connection.listen(onDeleteNotification);

    this.subscriptions.push(onReceiveMessage.subscribe(message => {
      this.addOrUpdateMessage(message);
    }));
    this.subscriptions.push(onDeleteNotification.subscribe(notificationId => {
      this.deleteMessage(notificationId);
    }));
  }

  private deleteMessage(notificationId: number): void {
    const notification = this.notifications.find(item => item.id === notificationId);
    if (notification) {
      const index = this.notifications.indexOf(notification);
      this.notifications.splice(index, 1);
    }
    this.updateCounter();
  }

  private connectToHub(): void {
    this.signalR.connect({
      hubName: 'NotificationHub',
      url: environment.signalRUrl,
      qs: {
        currentOrgTecId: this.currentOrganizationService.getCurrentOrganization().tecComId,
        userId: this.userService.currentUser.userUid
      }
    }).then(connection => {
      this.connection = connection;
      this.listenForMessages();

      this.subscriptions.push(this.connection.status.subscribe((status: ConnectionStatus) => {
        console.log(`SignalR: ${new Date()}; Status is = ${status}`);
        if (status.name === 'disconnected') {
          if (this.reconnectionCount === 3) {
            if (!this.reloadNotification) {
              this.showRefreshPageNotification();
            }

            return;
          }
          console.log(`SignalR: ${new Date()}; Re-establishing connection...`);
          setTimeout(() => {
            this.connection.start();
            this.reconnectionCount += 1;
          }, 10000);
        }
      }));
    });
  }

  private getNotifications(): void {
    this.subscriptions.push(this.notificationService.getNotifications()
      .subscribe(data => {
        data.forEach(element => {
          if (element.type === NotificationType.basket) {
            this.notifications.push(element);
            this.unreadBasketNotifications++;
          }
        });
        this.getClaimNotifications();
      }));

    this.ngZone.runOutsideAngular(() => this.connectToHub());
  }

  private getClaimNotifications(): void {
    const currentSalesOrg = this.currentOrganizationService.getCurrentOrganization();
    if (currentSalesOrg && currentSalesOrg.returnsEnabled) {
      this.subscriptions.push(this.notificationService.getClaimNotificationsForOrganization()
        .subscribe(data => {
          data.forEach(element => {
            if (element.type === NotificationType.claim) {
              this.notifications.push(element);
              this.unreadClaimNotifications++;
            }
          });
          this.notifications = this.notifications.sort((n, m) => n.id > m.id ? -1 : (n.id < m.id ? 1 : 0));
        }));
    } else {
      this.notifications = this.notifications.sort((n, m) => n.id > m.id ? -1 : (n.id < m.id ? 1 : 0));
    }
  }

  private showRefreshPageNotification(): void {
    const message = this.translationsService.instant('Common.ConnectionLostMessage');
    const action = this.translationsService.instant('Common.Refresh_Btn');
    this.ngZone.run(() => {
      this.reloadNotification = this.snackBar.open(message, action, {
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
      this.reloadNotification.onAction().subscribe(() => {
        this.reconnectionCount = 0;
        this.reloadNotification = null;
        document.location.reload();
      });
    });
  }
}
