import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environment';
import { UserModel } from '@auth/models/user/user.model';

@Injectable()
export class UserProfileService {

  constructor(private http: HttpClient) {
  }

  public getUser(): Observable<UserModel> {
    return this.http.get<UserModel>(`${environment.masterDataServiceUrl}/UserProfile`);
  }
}
