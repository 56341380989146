import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SignalRConfiguration, SignalRModule } from 'ng2-signalr';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgxsModule } from '@ngxs/store';
import { TecComMenuModule } from 'teccom-menu';
import { TecComNavigationModule } from 'teccom-navigation';
import { environment } from '@environment';

import { SharedModule } from '@shared/shared.module';
import { AuthRoutingModule } from '@auth/auth-routing.module';
import { UserService } from '@auth/services/user.service';
import { UserIdentityService } from '@auth/services/user-identity.service';
import { UserProfileService } from '@auth/services/user-profile.service';
import { DiscardChangesService } from '@auth/services/discard-changes.service';
import { OrganisationState } from '@auth/state/organisation.state';
import { UserProfileState } from '@auth/state/user-profile.state';
import { HeaderInfoAuthComponent } from '@auth/components/header-info-auth/header-info-auth.component';
import { AuthComponent } from '@auth/components/auth/auth.component';
import { NotificationMenuComponent } from '@auth/components/notification-menu/notification-menu.component';
import { BasketNotificationTxml4Component } from '@auth/components/notification-menu/components/basket-notification/basket-notification-txml4.component';
import { BasketNotificationTxml5Component } from '@auth/components/notification-menu/components/basket-notification/basket-notification-txml5.component';
import { ClaimNotificationComponent } from '@auth/components/notification-menu/components/claim-notification/claim-notification.component';
import { NotificationStatusTranslatorPipe } from '@auth/components/notification-menu/pipes/notification-status-translator.pipe';
import { ClaimNotificationStatusPipe } from '@auth/components/notification-menu/pipes/claim-notification-status.pipe';
import { ClaimNotificationCategoryPipe } from '@auth/components/notification-menu/pipes/claim-notification-category.pipe';
import { NotificationService } from '@auth/components/notification-menu/services/notification.service';
import { VisibilityManagerService } from '@auth/components/notification-menu/services/visibility-manager.service';
import { BasketNotificationLoaderTxml4Service } from '@auth/components/notification-menu/services/basket-notification-loader-txml4.service';
import { BasketNotificationLoaderTxml5Service } from '@auth/components/notification-menu/services/basket-notification-loader-txml5.service';
import { ClaimNotificationLoaderService } from '@auth/components/notification-menu/services/claim-notification-loader.service';
import { OrganizationSelectionComponent } from '@auth/components/organization-selection/organization-selection.component';
import { OrganizationSelectionState } from '@auth/state/organization-selection.state';
import { MenuService } from '@auth/services/menu.service';

const createConfig = () => {
  const c = new SignalRConfiguration();
  c.hubName = 'NotificationHub';
  c.url = environment.signalRUrl;

  return c;
};

@NgModule({
  declarations: [
    AuthComponent,
    HeaderInfoAuthComponent,
    NotificationMenuComponent,
    ClaimNotificationComponent,
    BasketNotificationTxml5Component,
    NotificationStatusTranslatorPipe,
    ClaimNotificationStatusPipe,
    ClaimNotificationCategoryPipe,
    BasketNotificationTxml4Component,
    OrganizationSelectionComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    AuthRoutingModule,
    ConfirmDialogModule,
    SignalRModule.forRoot(createConfig),
    NgxsModule.forFeature([OrganisationState, UserProfileState, OrganizationSelectionState]),
    TecComMenuModule,
    TecComNavigationModule
  ],
  exports: [
    AuthComponent,
    HeaderInfoAuthComponent,
    OrganizationSelectionComponent
  ],
  providers: [
    NotificationService,
    ConfirmationService,
    UserProfileService,
    DiscardChangesService,
    UserService,
    MenuService,
    UserIdentityService,
    VisibilityManagerService,
    BasketNotificationLoaderTxml4Service,
    BasketNotificationLoaderTxml5Service,
    ClaimNotificationLoaderService]
})
export class AuthModule { }
