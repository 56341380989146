import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { BasketNotificationComponent } from '@auth/components/notification-menu/components/basket-notification/basket-notification.component';
import { BasketNotificationLoaderTxml5Service } from '@auth/components/notification-menu/services/basket-notification-loader-txml5.service';

@Component({
  selector: 'tec-basket-notification-txml5',
  templateUrl: './basket-notification.component.html',
  styleUrls: ['./basket-notification.component.scss']
})
export class BasketNotificationTxml5Component extends BasketNotificationComponent {
  constructor(basketNotificationLoaderService: BasketNotificationLoaderTxml5Service, router: Router) {
    super(basketNotificationLoaderService, router, '/requestorder/basket-txml5');
  }
}
