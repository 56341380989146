export class VcToolRoutingConst {
  public static readonly VcToolRoot: string = 'vc-tool';
  public static readonly Reports: string = 'reports';
  public static readonly ReportsNew: string = 'reports/new';
  public static readonly Rules: string = 'rules';
  public static readonly Manual: string = 'manual';
  public static readonly ValidationResults: string = 'validation-results';
  public static readonly Certification: string = 'certification';
  public static readonly SubscriptionError: string = 'subscription-required';
  public static readonly ValidationProcess: string = 'validation-process';
  public static readonly ManageSuppliers: string = 'manage-suppliers';
  public static readonly Knowledge: string = 'knowledge';
}
