import { EventEmitter } from '@angular/core';
import { BasketNotificationModel } from '@auth/components/notification-menu/models/basket-notification.model';


export abstract class BasketNotificationLoaderService {
  onLoadBasket: EventEmitter<BasketNotificationModel> = new EventEmitter();
  onSetActiveBasket: EventEmitter<BasketNotificationModel> = new EventEmitter();

  loadBasket(basketNotification: BasketNotificationModel): void {
    this.onLoadBasket.emit(basketNotification);
  }

  setActiveBasket(basketNotification: BasketNotificationModel): void {
    this.onSetActiveBasket.emit(basketNotification);
  }
}
