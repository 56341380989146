export const BULGARIAN_KEY = 'bg';
export const CZECH_KEY = 'cs';
export const DANISH_KEY = 'da';
export const GERMAN_KEY = 'de';
export const GREEK_KEY = 'el';
export const ENGLISH_KEY = 'en';
export const SPANISH_KEY = 'es';
export const FINNISH_KEY = 'fi';
export const FRENCH_KEY = 'fr';
export const CROATIAN_KEY = 'hr';
export const HUNGARIAN_KEY = 'hu';
export const ITALIAN_KEY = 'it';
export const NORWEGIAN_KEY = 'nb';
export const DUTCH_KEY = 'nl';
export const POLISH_KEY = 'pl';
export const PORTUGUESE_KEY = 'pt';
export const ROMANIAN_KEY = 'ro';
export const RUSSIAN_KEY = 'ru';
export const SWEDISH_KEY = 'se';
export const SLOVAK_KEY = 'sk';
export const TURKISH_KEY = 'tr';
export const CHINESE_KEY = 'zh';

export const availableLanguages: string[] = [
  BULGARIAN_KEY,
  CZECH_KEY,
  DANISH_KEY,
  GERMAN_KEY,
  GREEK_KEY,
  ENGLISH_KEY,
  SPANISH_KEY,
  FINNISH_KEY,
  FRENCH_KEY,
  CROATIAN_KEY,
  HUNGARIAN_KEY,
  ITALIAN_KEY,
  NORWEGIAN_KEY,
  DUTCH_KEY,
  POLISH_KEY,
  PORTUGUESE_KEY,
  ROMANIAN_KEY,
  RUSSIAN_KEY,
  SWEDISH_KEY,
  SLOVAK_KEY,
  TURKISH_KEY,
  CHINESE_KEY
];
