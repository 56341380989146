import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CurrentOrganizationService } from '@auth/services/current-organization.service';
import { NotificationType } from '@auth/components/notification-menu/models/notification-type.enum';
import { NotificationModel } from '@auth/components/notification-menu/models/notification.model';
import { environment } from '@environment';

@Injectable()
export class NotificationService {
  constructor(private http: HttpClient,
              private currentOrgService: CurrentOrganizationService) { }

  public getNotifications(): Observable<NotificationModel[]> {
    const tecId = this.currentOrgService.currentOrganization.tecComId;

    return this.http.get<NotificationModel[]>(`${environment.masterDataServiceUrl}/notification/forUser/${tecId}`);
  }

  public getClaimNotificationsForOrganization(): Observable<NotificationModel[]> {
    const tecId = this.currentOrgService.currentOrganization.tecComId;

    return this.http.get<NotificationModel[]>(`${environment.masterDataServiceUrl}/notification/forOrganization/${tecId}`);
  }

  public deleteNotificationByProcessId(processId: number, notificationType: NotificationType): Observable<any> {
    const tecId = this.currentOrgService.currentOrganization.tecComId;

    return this.http.delete(
      `${environment.masterDataServiceUrl}/notification/DeleteByProcessId/${tecId}?processId=${processId}&type=${notificationType}`);
  }

  public deleteClaimNotificationByProcessId(processId: number): Observable<any> {
    const tecId = this.currentOrgService.currentOrganization.tecComId;

    return this.http.delete(`${environment.masterDataServiceUrl}/notification/DeleteClaimNotificationByProcessId/${tecId}?processId=${processId}`);
  }

  public deleteNotificationById(id: number): Observable<any> {
    const tecId = this.currentOrgService.currentOrganization.tecComId;

    return this.http.delete(`${environment.masterDataServiceUrl}/notification/DeleteById/${tecId}?notificationId=${id}`);
  }

  public deleteAllNotifications(): Observable<number> {
    const tecId = this.currentOrgService.currentOrganization.tecComId;

    return this.http.delete<number>(`${environment.masterDataServiceUrl}/notification/DeleteForUser/${tecId}`);
  }
}
