import { OrganisationMappingModel } from '@auth/models/user/organisation-mapping.model';

export class FetchOrganizationSelectionSuccess {
  static readonly type = '[OrganizationSelection] FetchOrganizationSelectionSuccess';

  constructor(public payload: {
    organizations: OrganisationMappingModel[],
    currentOrganization: OrganisationMappingModel
  }) {
  }
}
