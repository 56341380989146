export const getCurrentDomain = () => {
  const separatedDomain = window.location.hostname.split('.');
  separatedDomain.shift();
  const currentDomain = separatedDomain.join('.');

  return currentDomain;
};

export const getModuleHostname = (moduleSubRoute: string): string => {
  return joinSubdomainAndDomain(moduleSubRoute, getCurrentDomain());
};

export const joinSubdomainAndDomain = (subdomain: string, domain: string): string => {
  return subdomain ? `${subdomain}${domain}` : '';
};
