<div class="basket-notification" [ngClass]="{'disabled': model.status===0, 'pointer': isSelectable()}"
  (click)="onSelectClick()" [attr.data-test-id]="model.basketId"
  [title]="isSelectable() ? '' : 'Common.NotificationMenu_NotSelectable'|translate">
  <div class="text">
    <span class="name type" data-test-id="basket-notification-text"
      [ngClass]="getOrderType(model.status, model.orderType)">
    </span>
    <span>{{model.message}}</span>
  </div>
  <div class="notification-status" (click)="onDelete($event)">
    <div class="status" data-test-id="basket-notification-status"
      [ngClass]="getStatus(model.status)">{{model.status|notificationStatusTranslator|translate}}
      <span class="fa fa-trash"></span>
    </div>
  </div>
</div>