import { CatalogState } from '@auth/state/catalog.state';
import { Select } from '@ngxs/store';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { tap } from 'rxjs/operators';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [],
  imports: [
    SharedModule
  ]
})

export class CountryFlagsModule {
  @Select(CatalogState.getLanguages) private languages$: Observable<any[]>;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry) {

    this.languages$.pipe(tap(languages => {
      if (languages) {
        languages.forEach(l => {
          this.matIconRegistry.addSvgIcon(`flag_${l.id}`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(`../../assets/images/flags/4x3/${l.id}.svg`));
        });
      }
    })).subscribe();
  }

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CountryFlagsModule
    };
  }
}
