import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@auth/services/user.service';
import { map } from 'rxjs/operators';
import { navigate } from '@auth/utils/tecweb-utils';
import { environment } from '@environment';


@Injectable()
// a resolver can't be used for fetching the user details due to the order in which Angular runs the guards/resolvers:
// https://github.com/angular/angular/issues/24187
export class UserDetailsGuard implements CanActivate {
  constructor(
    private userService: UserService) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.userService.currentUser) {
      return true;
    }

    return this.userService.fetchUserData().pipe(map(user => {
      if (user) {
        return true;
      }
      navigate(environment.defaultRoute, environment.moduleSubdomains.tecweb);

      return false;
    }));
  }
}
