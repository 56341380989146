import { InjectionToken } from '@angular/core';

export const APP_SETTING = new InjectionToken('app.setting');
export const WINDOW_TOKEN = new InjectionToken('window');

export interface AppSetting {
  defaultRoute: string;
  supplierDefaultRoute: string;
  basketRoute: string;
  txml5BasketRoute;
  loginRoute: string;
  partnersRoute: string;
  returnOrderRoute: string;
  subscriptionsRoute: string;
  journalRoute: string;
  cloudIntegrationRoute: string;
  cookies: {
    language: string,
    filterSettings: {
      managerItemsPerPage: string,
      usersItemsPerPage: string,
      logsItemsPerPage: string,
      partnersItemsPerPage: string,
      invoiceItemsPerPage: string
    }
  };
  localStorage: {
    invitationRedirectNeeded: string,
    invitationLoadingNeeded: string,
    journalToBasketArticles: string,
    basketsToEdit: string,
    tecDocSession: string,
    eInvoicesToJournal: string,
    afterLoginRedirect: string
  };
  dataPrivacyCookiesUrls: { language: string; link: string }[];
  supportUrls: { language: string; link: string }[];
  gtcUrls: { language: string; link: string }[];
  supportedTroubleShootWikiUrl: string;
  supportedRegistrationWikiUrl: string;
  wikiHomePageUrl: string;
  wikiBusinessRelationUrl: string;
  wikiSupportedLanguages: string[];
  wikiDefaultLanguage: string;
  wikiShipToUsageUrl: string;
  wikiMessageFormatComparisonPage: string;
  wikiIfdManualPage: string;
  wikiRootUrl: string;
  statusPageUrl: string;
  hideBuyerPartyRoutes: string[];
  blobStorageUrl: string;
  lgpdDownloadLink: string;
}

// eslint-disable-next-line no-redeclare
export const AppSetting: AppSetting = {
  loginRoute: '/auth/welcome',
  defaultRoute: '/requestorder/basket',
  supplierDefaultRoute: '/home/dashboard',
  basketRoute: '/requestorder/basket',
  txml5BasketRoute: '/requestorder/mbasket',
  partnersRoute: '/administration/partners',
  returnOrderRoute: '/returns/claimlist',
  subscriptionsRoute: '/user/subscription',
  journalRoute: '/journal/order',
  cloudIntegrationRoute: '/administration/cloud',
  cookies: {
    language: 'Language',
    filterSettings: {
      managerItemsPerPage: 'managerItemsPerPage',
      usersItemsPerPage: 'usersItemsPerPage',
      logsItemsPerPage: 'logsItemsPerPage',
      partnersItemsPerPage: 'partnersItemsPerPage',
      invoiceItemsPerPage: 'invoiceItemsPerPage'
    }
  },
  localStorage: {
    invitationRedirectNeeded: 'invitation-redirect-needed',
    invitationLoadingNeeded: 'invitation-loading-needed',
    journalToBasketArticles: 'journal-to-basket-articles',
    basketsToEdit: 'basket-manager-edit',
    tecDocSession: 'tecdoc-session',
    eInvoicesToJournal: 'e-invoices-to-order-journal',
    afterLoginRedirect: 'after-login-redirect'
  },
  dataPrivacyCookiesUrls: [
    { language: 'de',
      link: 'https://www.tecalliance.net/de/privacy-policy/' },
    { language: 'en',
      link: 'https://www.tecalliance.net/privacy-policy/' }
  ],
  gtcUrls: [
    { language: 'de',
      link: 'https://www.tecalliance.net/de/gtcs/' },
    { language: 'en',
      link: 'https://www.tecalliance.net/gtcs/' }
  ],
  supportUrls: [
    { language: 'en',
      link: 'https://support.tecalliance.net/' }
  ],
  wikiRootUrl: 'https://help-teccom.tecalliance.net',
  supportedTroubleShootWikiUrl: 'troubleshooting/error-list#tec-',
  supportedRegistrationWikiUrl: 'order-manager-login/user-registration',
  wikiHomePageUrl: 'home',
  wikiBusinessRelationUrl: 'administration/businessrelations',
  wikiShipToUsageUrl: 'Order/Ship-To-Usage',
  wikiMessageFormatComparisonPage: 'Order/Message-Format-Comparison',
  wikiIfdManualPage: 'Order/Ifd-Manual',
  wikiSupportedLanguages: ['en', 'de', 'es', 'fr', 'it', 'pt'],
  wikiDefaultLanguage: 'en',
  statusPageUrl: 'https://status.tecalliance.net/',
  hideBuyerPartyRoutes: ['/requestorder/mbasket'],
  blobStorageUrl: 'https://tecwebqastorage.blob.core.windows.net',
  lgpdDownloadLink: 'https://tecwebqastorage.blob.core.windows.net/newsletter-content/TecAlliance-GTC-em-Portugues.pdf'
};

