import { PipeTransform, Pipe } from '@angular/core';

import { ClaimCategoryType } from '@auth/components/notification-menu/models/claim-category-type.enum';

@Pipe({
  name: 'claimNotificationCategory'
})
export class ClaimNotificationCategoryPipe implements PipeTransform {
  transform(value: any): any {
    const claimCategory = ClaimCategoryType;
    const category = claimCategory[value];
    if (category) {
      return `Claims.Status_${category}`;
    }

    return value;
  }
}
