import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { TranslationsService } from '@core/translations/translations.service';

@Injectable()
export class NotificationsService {
  private options = {
    enableHtml: true,
    tapToDismiss: true,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true
  };

  private errorOptions = {
    enableHtml: true,
    disableTimeOut: true,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true
  };

  constructor(
    private toastrService: ToastrService,
    private translationsService: TranslationsService) { }

  success(message: string, title: string = null): void {
    this.toastrService.success(
      message && this.translationsService.instant(message),
      title && this.translationsService.instant(title),
      this.options);
  }

  warning(message: string, title: string = null): void {
    this.toastrService.warning(
      message && this.translationsService.instant(message),
      title && this.translationsService.instant(title),
      this.options);
  }

  error(message: string, title: string = null): void {
    this.toastrService.error(
      message && this.translationsService.instant(message),
      title && this.translationsService.instant(title), this.errorOptions);
  }

  info(message: string, title: string = null): void {
    this.toastrService.info(
      message && this.translationsService.instant(message),
      title && this.translationsService.instant(title), this.options);
  }
}
