import { JwtConfig } from '@auth0/angular-jwt';

import { environment } from '@environment';
import { OktaAuth } from '@okta/okta-auth-js';

export const JwtOptionsFactory = (oktaAuth: OktaAuth) => {
  return {
    tokenGetter: () => {
      return oktaAuth.getAccessToken() || '""';
    },
    allowedDomains: environment.whitelistedDomains
  } as JwtConfig;
};
