import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ValidationReleaseModel } from '@app/shared/models/validation-release.model';
import { ValidationReleaseHttpService } from '@app/shared/services/validation-release-http.service';
import { AddValidationRelease } from '@app/vc-tool/state/actions/add-validation-release.action';
import { ValidationReleaseState } from '@app/vc-tool/state/validation-release.state';
import { Store } from '@ngxs/store';
import { combineLatest, map, timer } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'ta-header',
  templateUrl: './ta-header.component.html'
})
export class TaHeaderComponent implements OnInit, OnDestroy {
  validationRelease: ValidationReleaseModel; 

  @Input() title: string;

  private subscriptions: Subscription[] = [];

  constructor(private validationReleaseService: ValidationReleaseHttpService, private store: Store) { }

  ngOnInit(): void {
    this.subscriptions.push(this.store.select(ValidationReleaseState.getValidationRelease)
      .subscribe(data => {
        if (!data) {
          this.loadData();

          return;
        }

        this.validationRelease = data;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private loadData(): void {
    this.subscriptions.push(
      combineLatest([timer(1000), this.validationReleaseService.getValidationRelease()])
        .pipe(map(x => x[1]))
        .subscribe(
          data => {
            this.store.dispatch(new AddValidationRelease(data));
          }
        )
    );
  }
}
