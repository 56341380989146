import { CatalogService } from '@auth/services/catalog.service';
import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { FetchCatalog } from '@auth/state/actions/catalog/catalog.action';
import { CatalogStateModel } from '@auth/state/models/catalog.model';
import { ListItemKey } from '@shared/models/list-item-key.model';

const defaults: CatalogStateModel = {
  languages: []
};

@State<CatalogStateModel>({
  name: 'catalog',
  defaults
})
@Injectable()
export class CatalogState implements NgxsOnInit {
  constructor(private catalogService: CatalogService) { }

  @Selector()
  static getLanguages(state: CatalogStateModel): ListItemKey[] {
    return state.languages;
  }

  ngxsOnInit(ctx: StateContext<CatalogStateModel>): void {
    ctx.dispatch(new FetchCatalog());
  }

  @Action(FetchCatalog)
  fetchCatalog(ctx: StateContext<CatalogStateModel>): void {
    const currentState = ctx.getState();

    if (currentState.languages.length === 0) {
      ctx.setState({
        ...ctx.getState(),
        languages: [...this.catalogService.getLanguages()]
      });
    }
  }
}
