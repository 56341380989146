import { NgModule } from '@angular/core';
import { RouterModule, Routes, ActivatedRouteSnapshot } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { UserDetailsGuard } from '@core/guards/user-details.guard';

import { AuthComponent } from '@auth/components/auth/auth.component';

const routes: Routes = [
  {
    path: 'callback',
    component: OktaCallbackComponent
  },
  {
    path: '',
    component: AuthComponent,
    canActivate: [OktaAuthGuard, UserDetailsGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('@vc-tool/vc-tool.module').then(m => m.VCToolModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [{
    provide: 'externalUrlRedirectResolver',
    useValue: (route: ActivatedRouteSnapshot) => {
      window.location.href = (route.data as any).externalUrl;
    }
  }]
})
export class AuthRoutingModule { }
