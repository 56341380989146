import { Inject, Injectable } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { ConfirmationService } from 'primeng/api';

import { DiscardChangesService } from '@app/auth/services/discard-changes.service';
import { TranslationsService } from '../translations/translations.service';
import { OKTA_AUTH } from '@okta/okta-angular';

const AUTHENTICATION_DATA_KEYS: string[] = [
  'okta-token-storage',
  'okta-shared-transaction-storage',
  'okta-cache-storage',
  'okta-original-uri-storage'
];

@Injectable()
export class AuthenticationService {

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private translateService: TranslationsService,
    private discardChangesService: DiscardChangesService,
    private confirmationService: ConfirmationService) { }

  public async logout(event?: Event, showConfirmationDialog = false): Promise<void> {
    if (event) {
      event.preventDefault();
    }

    if (!showConfirmationDialog) {
      await this.oktaAuth.signOut();

      return;
    }

    this.confirmationService.confirm({
      key: 'header-info-confirmation',
      header: this.translateService.instant('Logout.Logout_Title'),
      message: this.discardChangesService.isDirty() ?
        this.translateService.instant('Logout.Logout_DiscardContent') :
        this.translateService.instant('Logout.Logout_Content'),
      accept: async () => {
        window.dispatchEvent(new Event('gototecwebpage'));
        this.discardChangesService.clearAllDirty();
        await this.oktaAuth.signOut();
      }
    });
  }

  async login(): Promise<void> {
    await this.oktaAuth.signInWithRedirect();
  }

  getToken(): string {
    return this.oktaAuth.getAccessToken();
  }

  clearAuthData() {
    AUTHENTICATION_DATA_KEYS.forEach(key => window.localStorage.removeItem(key));
  }
}
