import { Injectable } from '@angular/core';
import { ListItemKey } from '@shared/models/list-item-key.model';
import * as languages from '../../../assets/catalog/languages.json';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  public getLanguages(): ListItemKey[] {
    return (languages as any).default;
  }
}
