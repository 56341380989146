import { Pipe, PipeTransform } from '@angular/core';

import { fromUTC } from '@core/utils/date.utils';

@Pipe({
  name: 'utcToLocalTime'
})
export class UtcToLocalTimePipe implements PipeTransform {
  transform(value: any): any {
    return fromUTC(new Date(value)).toString();
  }
}
