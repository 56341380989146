import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';

import { BasketNotificationModel } from '@auth/components/notification-menu/models/basket-notification.model';
import { RequestStatusType } from '@auth/components/notification-menu/models/request-status.type.enum';
import { BasketNotificationLoaderService } from '@auth/components/notification-menu/services/basket-notification-loader.service';

@Directive()

export abstract class BasketNotificationComponent {
  @Input() model: BasketNotificationModel;
  @Input() notification: Notification;
  @Output() onNotificationClicked: EventEmitter<Notification> = new EventEmitter<Notification>();
  @Output() onDeleteNotification: EventEmitter<Notification> = new EventEmitter<Notification>();

  protected constructor(
    private basketNotificationLoaderService: BasketNotificationLoaderService,
    private router: Router,
    private url: string) {
  }

  onSelectClick(): void {
    if (this.model.status === RequestStatusType.InProgress) {
      return;
    }

    if (environment.currentModule !== undefined && environment.currentModule !== environment.moduleSubdomains.tecweb) {
      return;
    }

    localStorage.setItem('basketNotification', JSON.stringify(this.model));
    this.router.navigate([this.url]);
    this.basketNotificationLoaderService.setActiveBasket(this.model);
    this.onNotificationClicked.emit(this.notification);
  }

  onDelete(event: Event): void {
    if (this.model.status === RequestStatusType.InProgress) {
      return;
    }
    event.stopPropagation();
    this.onDeleteNotification.emit(this.notification);
  }

  getStatus(status: number): string {
    switch (status) {
    case 1:
      return 'success';
    case 2:
      return 'error';
    default:
      return '';
    }
  }

  getOrderType(status: number, orderType: number): string {
    if (status > 0) {
      switch (orderType) {
      case 0:
        return 'request';
      case 1:
        return 'order';
      case 3:
        return 'restock';
      default:
        return '';
      }
    }

    return '';
  }

  isSelectable(): boolean {
    return environment.currentModule === undefined || environment.currentModule === environment.moduleSubdomains.tecweb;
  }
}
