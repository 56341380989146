import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '@auth/services/user.service';
import { navigate } from '@auth/utils/tecweb-utils';
import { environment } from '@environment';

@Injectable()
export class CanActivateTecComAdminGuard implements CanActivate {
  constructor(private userService: UserService) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.userService.isTecComAdmin()) {
      navigate(environment.defaultRoute, environment.moduleSubdomains.tecweb);

      return false;
    }

    return true;
  }
}
