import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Router } from '@angular/router';

import { UserStateModel } from '@auth/state/models/user.model';
import { CurrentOrganizationService } from '@auth/services/current-organization.service';
import { UserService } from '@auth/services/user.service';
import { ChangeOrganisation } from '@auth/state/actions/organization/change-organisation.action';
import { OrganisationMappingModel } from '@auth/models/user/organisation-mapping.model';
import { VcToolRoutingConst } from '@vc-tool/vc-tool-routing.const';
import { RetrieveOrganisation } from '@auth/state/actions/organization/retrieve-organisation.action';
import { CookieService } from 'ngx-cookie-service';
import { FetchCookieConsent } from '@app/vc-tool/state/actions/cookie-consent-fetch.actions';
import { CookieConsentConst } from '@app/shared/cookie-consent/cookie-consent.const';
import { ResetCookieConsent } from '@app/vc-tool/state/actions/cookie-consent-reset.actions';
import { SetCookieConsent } from '@app/vc-tool/state/actions/cookie-consent-set.actions';
import { TecWebUrlService } from 'teccom-navigation';

@State<UserStateModel>({
  name: 'user',
  defaults: {
    cookieConsent: null,
    currentOrganisation: null,
    pageFilters: { }
  } as UserStateModel
})
@Injectable()
export class OrganisationState {

  private static readonly CookieConsentExpiration = 365;

  constructor(
    private userService: UserService,
    private router: Router,
    private currentOrganizationService: CurrentOrganizationService,
    private cookieService: CookieService,
    private urlService: TecWebUrlService) {
  }

  @Selector()
  static currentOrganization(state: UserStateModel): OrganisationMappingModel {
    return state.currentOrganisation;
  }

  @Selector()
  static currentOrganizationRole(state: UserStateModel): number {
    return state.currentOrganisation.organisationRole;
  }

  @Selector()
  static cookieConsent(state: UserStateModel): string {
    return state.cookieConsent;
  }

  @Action(ChangeOrganisation)
  public changeOrganisation(ctx: StateContext<UserStateModel>, action: ChangeOrganisation): void {
    if (!action.currentOrganisation) {
      return;
    }

    const currentUser = this.userService.currentUser;
    const newOrg = currentUser.userOrganisationMappings.find((mapping: OrganisationMappingModel) =>
      mapping.tecComId === action.currentOrganisation);
    this.currentOrganizationService.setCurrentOrganization(newOrg);

    const state = ctx.getState();

    if (
      !this.userService.isTecComAdmin() &&
      state.currentOrganisation && // first load
      !this.router.url.endsWith(VcToolRoutingConst.Rules) &&
      !this.router.url.endsWith(`${VcToolRoutingConst.VcToolRoot}/${VcToolRoutingConst.Reports}`)) {
      this.router.navigate([`${VcToolRoutingConst.VcToolRoot}/${VcToolRoutingConst.Reports}`]);
    }

    ctx.setState({ ...state, currentOrganisation: this.currentOrganizationService.getCurrentOrganization() });
  }

  @Action(RetrieveOrganisation)
  public retrieveOrganisation(ctx: StateContext<UserStateModel>): void {
    const state = ctx.getState();
    ctx.setState({ ...state, currentOrganisation: this.currentOrganizationService.getCurrentOrganization() });
  }

  @Action(SetCookieConsent)
  public setCookieConsent(ctx: StateContext<UserStateModel>, action: SetCookieConsent): void {
    this.cookieService.set(
      CookieConsentConst.CookieKey,
      action.cookieConsent,
      OrganisationState.CookieConsentExpiration,
      '/',
      this.urlService.getCurrentDomain());
    ctx.patchState({ cookieConsent: action.cookieConsent });
  }

  @Action(FetchCookieConsent)
  public fetchCookieConsent(ctx: StateContext<UserStateModel>): void {
    const consent = this.cookieService.get(CookieConsentConst.CookieKey);
    ctx.dispatch(new SetCookieConsent(consent));
  }

  @Action(ResetCookieConsent)
  public resetCookieConsent(ctx: StateContext<UserStateModel>): void {
    this.cookieService.delete(CookieConsentConst.CookieKey, '/', this.urlService.getCurrentDomain());
    ctx.patchState({ cookieConsent: null });
  }

}
