import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { CurrentOrganizationService } from '@app/auth/services/current-organization.service';
import { APP_SETTING, AppSetting } from '@app/core/app-settings';
import { UiLanguageService } from '@app/core/services/ui-language.service';

@Component({
  selector: 'order-pro-chip',
  templateUrl: './order-pro-chip.component.html',
  styleUrls: ['./order-pro-chip.component.scss']
})
export class OrderProChipComponent implements OnInit, AfterViewInit {
  @Input() size = 60;
  @Input() isShowOnlyTxml5 = false;

  orderProLogoUrl: string;

  constructor(
    private languageService: UiLanguageService,
    private currentOrganizationService: CurrentOrganizationService,
    @Inject(APP_SETTING) private appSettings: AppSetting) {
  }

  ngOnInit(): void {
    this.orderProLogoUrl = this.getOrderProLogoUrl();
  }

  ngAfterViewInit(): void {
    this.displayOrderProToolTip();
  }

  onClickOrderProHelp(): void {
    window.open(this.getCorrectPDFLanguage(), '_blank');
  }

  private displayOrderProToolTip(): void {
    if (this.currentOrganizationService.isOrgFocusedOrderPro() &&
      !this.currentOrganizationService.isSupplier()) {
      document.getElementById('order-pro-tooltip').click();
    }
  }

  private getOrderProLogoUrl(): string {
    return 'assets/images/order-pro/order-pro-excellent-program.svg';
  }

  private getCorrectPDFLanguage(): string {
    const currentLanguage = this.languageService.activeLanguage || 'en';
    switch (currentLanguage) {
    case 'es': return `${this.appSettings.blobStorageUrl}/newsletter-content/TecCom-excellence-program-ES.pdf`;
    case 'de': return `${this.appSettings.blobStorageUrl}/newsletter-content/TecCom-excellence-program-DE.pdf`;
    default: return `${this.appSettings.blobStorageUrl}/newsletter-content/TecCom-excellence-program-EN.pdf`;
    }
  }
}
