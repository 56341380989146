import { Injectable } from '@angular/core';

@Injectable()
export class VisibilityManagerService {
  isMenuVisible = false;

  public hideMenu(): void {
    this.isMenuVisible = false;
  }

  public showMenu(): void {
    this.isMenuVisible = true;
  }
}
