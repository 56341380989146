<header class="tec-header" [ngClass]="{'mobile-display': isMobileDevice}">
  <div class="tec-header-logo">
    <div class="header-logo"></div>
  </div>

  <div class="tec-header-controls">
    <tec-organization-selection></tec-organization-selection>
    <div class="upgrade-section">
      <button class="upgrade-button" mat-button (click)="onUpgradeClick($event)">
        <span class="icon crown fa fa-crown"></span>
        <span class="text">{{'Menu.Menu_Subscription' | translate}}</span>
      </button>
    </div>

    <div class="tec-header-notifications">
      <tec-notification-menu></tec-notification-menu>
    </div>


    <div class="utility-section">
      <ng-container>
        <button class="profile-dropdown-button" [matMenuTriggerFor]="profile"
                (menuClosed)="menuClosed('profile-dropdown-icon')" (menuOpened)="menuOpened('profile-dropdown-icon')"
                mat-button>
          <span class="icon fa fa-circle-user"></span>
          <span class="icon fa fa-caret-down" style="margin-left: 8px; font-size: 10px;"
                id="profile-dropdown-icon"></span>
        </button>
      </ng-container>
      <mat-menu #profile>
        <ng-container>
          <button mat-menu-item class="profile-dropdown" (click)="onUserProfileClick($event)">
            <span class="icon fa fa-user"></span>
            <span class="text">{{ 'Menu.Menu_UserProfile' | translate}}</span>
          </button>
        </ng-container>
        <ng-container>
          <button data-test-id="logoutButton" id="logoutButton" mat-menu-item class="profile-dropdown"
                  (click)="onLogout($event)">
            <span class="icon fa fa-sign-out"></span>
            <span class="text">{{ 'Help.Help_Logout' | translate}}</span>
          </button>
        </ng-container>
      </mat-menu>
    </div>
    <div class="hamburger-menu-space"></div>
  </div>
</header>