<div class="organization-selection-container" *ngIf="organizationList.length !== 0">
  <mat-card-subtitle *ngIf="isShowSubTitle"
                     class="organization-selection-title">{{'Organizations.Current_Organization' | translate}}
  </mat-card-subtitle>
  <mat-select matNativeControl data-test-id="organisationMenu" id="organisation-menu" [(value)]="organizationSelected"
              (selectionChange)="onOrgChanged($event.value)" [style.backgroundColor]="getSelectedOrgBackgroundColor()"
              class="organization-selection"
              *ngIf="this.organizationList.length > 1;else hasOneOrganization">
    <mat-select-trigger [style.color]="getContrastYIQ(getSelectedOrgBackgroundColor())">
      <div
        class="organization-select-trigger">{{ organizationSelected ? organizationSelected.organisationName : '' }}</div>
    </mat-select-trigger>
    <mat-option *ngFor="let organization of organizationList" [value]="organization"
                [attr.data-test-value]='organization.organisationName'
                [style.backgroundColor]="getOrgBackgroundColor(organization)"
                [style.color]="getContrastYIQ(getOrgBackgroundColor(organization))"
                class="organization-selection-option">
      <div>{{organization.organisationName}}</div>
    </mat-option>
  </mat-select>
  <ng-template #hasOneOrganization>
    <mat-card-title class="organization-name">
      {{ organizationSelected ? organizationSelected.organisationName : '' }}
    </mat-card-title>
  </ng-template>
</div>