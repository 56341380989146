import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

import { LocaleSettingsService } from '@core/services/locale-settings.service';

@Pipe({
  name: 'date'
})
export class ExtendDatePipe extends DatePipe implements PipeTransform {
  private customFormats: any;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private localeSettingsService: LocaleSettingsService) {
    super(localeSettingsService.culture);

    this.customFormats = {
      date: this.localeSettingsService.dateFormat,
      time: this.localeSettingsService.timeFormat,
      dateTime: `${this.localeSettingsService.dateFormat} ${this.localeSettingsService.timeFormat}`
    };
  }

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(
    value: Date | string | number | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string): string | null {
    const dateFormat = this.customFormats[format] || format;

    return super.transform(value, dateFormat, timezone, locale);
  }
}
