
import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '@core/utils/string-helper';

@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {

  transform(value: string, args: any[]): string {
    return StringHelper.format(value, args);
  }

}
