import { Component, Input, Output, EventEmitter } from '@angular/core';
import { fromUTC } from '@core/utils/date.utils';

import { NotificationModel } from '@auth/components/notification-menu/models/notification.model';
import { ClaimNotificationModel } from '@auth/components/notification-menu/models/claim-notification.model';

@Component({
  selector: 'tec-claim-notification',
  templateUrl: './claim-notification.component.html',
  styleUrls: ['./claim-notification.component.scss']
})
export class ClaimNotificationComponent {
  @Input() model: ClaimNotificationModel;
  @Input() notification: NotificationModel;
  @Output() onDeleteNotification: EventEmitter<NotificationModel> = new EventEmitter<NotificationModel>();

  getFormattedDateTime(date: any): string {
    return fromUTC(new Date(date)).toString();
  }

  onDelete(event: Event): void {
    event.stopPropagation();
    this.onDeleteNotification.emit(this.notification);
  }
}
