import { Injectable } from '@angular/core';
import { TaHttpClient } from '@app/core/http/ta-http-client';
import { Observable } from 'rxjs';
import { ValidationReleaseModel } from '../models/validation-release.model';

@Injectable()
export class ValidationReleaseHttpService {
  constructor(private http: TaHttpClient) {
  }

  getValidationRelease(): Observable<ValidationReleaseModel> {
    return this.http.get<ValidationReleaseModel>('ValidationRelease/release');
  }
}