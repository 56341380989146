import { CurrentOrganizationService } from '@auth/services/current-organization.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

@Injectable()
export class TecComIdInterceptor implements HttpInterceptor {

  constructor(private currentOrganizationService: CurrentOrganizationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    if (request.url.startsWith('api/')) {
      const tecComId = this.currentOrganizationService.currentOrganization.tecComId;
      const clonedRequest = request.clone({
        setParams: {
          ...request.params,
          tecComId
        }
      });

      return next.handle(clonedRequest);
    }

    return next.handle(request);
  }
}
