import { Inject, Injectable } from '@angular/core';
import { CatalogService } from '@app/auth/services/catalog.service';
import { CookieService } from 'ngx-cookie-service';
import { TecWebUrlService } from 'teccom-navigation';
import { APP_SETTING, AppSetting } from '../app-settings';

@Injectable()
export class UiLanguageService {
  private defaultLanguage = 'en';

  constructor(private cookieService: CookieService,
    private catalogService: CatalogService,
    private urlService: TecWebUrlService,
    @Inject(APP_SETTING) private appSetting: AppSetting) { }

  public get activeLanguage(): string {
    let activeLanguage = this.cookieService.get(this.appSetting.cookies.language);
    const languages = this.catalogService.getLanguages();

    if (!activeLanguage) {
      const navigatorLanguage = window.navigator.language ;
      if(navigatorLanguage === 'pt-BR')
        activeLanguage = 'pt-BR';
      else
        activeLanguage = navigatorLanguage?.substring(0, 2);
    }

    if (!languages.some(l => l.id === activeLanguage)) {
      activeLanguage = this.defaultLanguage;
    }

    return activeLanguage;
  }

  public setActiveLanguage(languageCode: string): void {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    this.cookieService.set(this.appSetting.cookies.language, languageCode, expires, '/', this.urlService.getCurrentDomain());
  }

  public getDatepickerLocale(): string {
    const activeLang = this.activeLanguage;
    let datepickerLang = '';

    switch (activeLang) {
    case 'pt':
      datepickerLang = 'pt-br';
      break;
    case 'se':
      datepickerLang = 'sv';
      break;
    case 'zh':
      datepickerLang = 'zh-cn';
      break;
    case 'nb':
      datepickerLang = 'no';
      break;
    default:
      datepickerLang = activeLang;
      break;
    }

    return datepickerLang;
  }
}
