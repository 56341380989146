import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthenticationInterceptor } from '@core/http-interceptors/authentication.interceptor';
import { TecComIdInterceptor } from '@core/http-interceptors/tec-com-id.interceptor';
import { CacheControlInterceptor } from '@core/http-interceptors/cache-control.interceptor';
import { ActiveSubscriptionInterceptor } from '@core/http-interceptors/active-subscription.interceptor';

export const HttpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CacheControlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TecComIdInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ActiveSubscriptionInterceptor, multi: true }
];
