import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CurrentOrganizationService } from '@auth/services/current-organization.service';
import { UserProfileService } from '@auth/services/user-profile.service';
import { UserModel } from '@auth/models/user/user.model';
import { FetchUserProfileSuccess } from '@auth/state/actions/user/user-profile-success.action';
import { FetchUserProfile } from '@auth/state/actions/user/user-profile.action';

export interface UserProfileStateModel {
  user: UserModel;
}

const defaults: UserProfileStateModel = {
  user: null
};

@State<UserProfileStateModel>({
  name: 'userProfileState',
  defaults
})
@Injectable()
export class UserProfileState {
  constructor(
    private profileService: UserProfileService,
    private orgService: CurrentOrganizationService) {
  }

  @Selector()
  static user(state: UserProfileStateModel): UserModel {
    return state.user;
  }

  @Action(FetchUserProfile)
  fetchUserProfile(ctx: StateContext<UserProfileStateModel>): Observable<UserModel> {
    return this.profileService.getUser()
      .pipe(
        tap((result: UserModel) => ctx.dispatch(new FetchUserProfileSuccess(result)))
      );
  }

  @Action(FetchUserProfileSuccess)
  fetchUserProfileSuccess(ctx: StateContext<UserProfileStateModel>, action: FetchUserProfileSuccess): void {
    const currentState = ctx.getState();
    const profile = action.payload;
    ctx.setState({
      ...currentState,
      user: {
        ...profile,
        userProfile: { ...profile.userProfile, currentTecId: this.orgService.getCurrentOrganization().tecComId }
      }
    });
  }
}
