import { Component, OnInit } from '@angular/core';

import { TranslationsService } from '@core/translations/translations.service';
import { Store } from '@ngxs/store';
import { FetchCookieConsent } from './vc-tool/state/actions/cookie-consent-fetch.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(private translationService: TranslationsService,
              private  store: Store) {
    translationService.registerLocalesData();
    translationService.applyLanguage();
  }

  ngOnInit(): void {
    this.store.dispatch(new FetchCookieConsent());
  }
}
