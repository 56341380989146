import { Component, Input } from '@angular/core';

@Component({
  selector: 'ta-icon-applicable',
  templateUrl: './ta-icon-applicable.component.html',
  styleUrls: ['./ta-icon-applicable.component.scss']
})
export class TaIconApplicableComponent {
  @Input() value: boolean;
}
