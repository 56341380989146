<div id="wrapper" [ngClass]="{'mobile-display': isMobileDevice}">
  <div class="header-wrapper" *ngIf="isShowTopNavigation">
    <tec-header-info-auth class="app-header" [userData]="userData">
    </tec-header-info-auth>

    <lib-teccom-menu [menuSettings]="menuSettings" [menuParameter]="menuParams" [activeLink]="currentRoute"
        [languages]="languages$|async" [selectedLanguage]="selectedLanguage" 
        (navigate)="onNavigate({ link: $event?.link, isExternal: $event?.isExternal, isRedirect: $event?.isRedirect })"
        (languageChange)="onLanguageChange($event)" (signOut)="onLogout($event)">
    </lib-teccom-menu>
  </div>

  <div class="app-main">
    <section class="main-section">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </section>
  </div>
  <p-confirmDialog class="tec-confirmation" key='header-info-confirmation'
                   [acceptLabel]="'DiscardChanges.DiscardChanges_Yes' | translate"
                   [rejectLabel]="'DiscardChanges.DiscardChanges_No' | translate" [closable]="false" 
                   defaultFocus="none">
  </p-confirmDialog>
  <p-confirmDialog class="tec-confirmation" key='warning-confirmation'
                   [acceptLabel]="'DiscardChanges.DiscardChanges_Yes' | translate" [closable]="false" 
                   defaultFocus="none"
                   [rejectLabel]="'DiscardChanges.DiscardChanges_No' | translate">
  </p-confirmDialog>

  <lib-teccom-footer [footerSettings]="menuSettings.footer" [languages]="languages$|async"
                     [selectedLanguage]="selectedLanguage" [isLoggedIn]="true" 
                     (languageChange)="onLanguageChange($event)"
                     (navigate)="onNavigate({ link: $event?.link, isExternal: $event?.isExternal, isRedirect: $event?.isRedirect })"
                     (cookieSettings)="onCookieSettings()">
  </lib-teccom-footer>
</div>