import { Inject, Injectable } from '@angular/core';
import { UserIdentity } from '@core/models/authentication/user-identity';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UserIdentityService {

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {
  }
  public userIdentity(): Observable<UserIdentity> {
    const userClaims = from(this.oktaAuth.getUser());

    return userClaims.pipe(map(x => (
      {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        login: x['email'],
        // eslint-disable-next-line @typescript-eslint/dot-notation
        userId: x['sub'],
        // eslint-disable-next-line @typescript-eslint/dot-notation
        roles: x['admingroups'] ?? []
      } as UserIdentity
    )));
  }
}
