import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '@core/services/authentication.service';
import { navigate } from '@auth/utils/tecweb-utils';
import { environment } from '@environment';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError(error => {
          if (error.status === 401) {
            this.authenticationService.logout();
          }

          if (error.status === 403) {
            navigate(environment.defaultRoute, environment.moduleSubdomains.tecweb);
          }

          return observableThrowError(error);
        }));
  }
}
