/**
 * Treats input date as UTC and converts it to local time.
 * @example: Jul 21 2019 00:00:00 GMT+0300 -> Jul 21 2019 03:00:00 GMT+0300
 */
export function fromUTC(date: Date): Date {
  return new Date(Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()));
}

/**
 * Converts local date to UTC.
 * @example: Jul 21 2019 03:00:00 GMT+0300 -> Jul 21 2019 00:00:00 GMT+0300
 */
export function toUTC(date: Date): Date {
  return (date === null || date === undefined)
    ? date
    : new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds());
}

export function removeTime(date: Date): Date {
  const result = new Date(date);
  result.setHours(0, 0, 0, 0);

  return result;
}

export function removeSeconds(date: Date): Date {
  const result = new Date(date);
  result.setSeconds(0, 0);

  return result;
}

export function getToday(): Date {
  return removeTime(new Date());
}

export function getNow(): Date {
  return new Date();
}

export function timestamp(): number {
  return getNow().getTime();
}
