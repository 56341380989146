
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IMenuParameters, IMenuSettings } from 'teccom-menu';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { environment } from '@environment';

import { Permissions } from '@auth/models/permission.enum';
import { UserModel } from '@auth/models/user/user.model';
import { ChangeOrganisation } from '@auth/state/actions/organization/change-organisation.action';
import { CurrentOrganizationService } from '@auth/services/current-organization.service';
import { UserService } from '@auth/services/user.service';
import { MenuService } from '@app/auth/services/menu.service';
import { OrganisationCategoryEnum } from '@auth/models/user/organization-category.enum';
import { AuthenticationService } from '@core/services/authentication.service';
import { UiLanguageService } from '@core/services/ui-language.service';
import { CatalogState } from '@auth/state/catalog.state';
import { DiscardChangesService } from '@auth/services/discard-changes.service';
import { LanguageChangeAction } from '@auth/state/actions/language/language-change.actions';
import { TecComPortalResizeAction } from '@auth/state/actions/teccom-menu-actions';
import { VisibilityManagerService } from '@auth/components/notification-menu/services/visibility-manager.service';
import { UserOrganisationRefreshed } from '@auth/state/actions/user/user-organisation-refreshed.action';
import { ResetCookieConsent } from '@app/vc-tool/state/actions/cookie-consent-reset.actions';

@Component({
  selector: 'auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  @Select(CatalogState.getLanguages) public languages$: Observable<any[]>;

  public userData: UserModel;
  public viewportBlockerId: string;
  public menuSettings: IMenuSettings;
  public isAdmin: boolean;
  public isSupplier: boolean;
  public isWorkshop: boolean;
  public isTecConnect: boolean;
  public isReturns: boolean;
  public isReturnsBetaEnabled: boolean;
  public isOrderManagerBetaEnabled: boolean;
  public isArticleData: boolean;
  public userRoles: string[];
  public permissions: string[] = [];
  public menuParams: IMenuParameters;
  public currentRoute = '';
  public selectedLanguage: string;
  public currentPosition = window.pageYOffset;
  public isShowTopNavigation = true;
  public isMobileDevice = false;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private translateService: TranslateService,
    private userService: UserService,
    private currentOrganizationService: CurrentOrganizationService,
    private visibilityManagerService: VisibilityManagerService,
    private discardChangesService: DiscardChangesService,
    private confirmationService: ConfirmationService,
    private actions$: Actions,
    private store: Store,
    private uiLanguageService: UiLanguageService,
    private authService: AuthenticationService,
    private menuService: MenuService) { }

  ngOnInit(): void {
    this.buildMenu();

    this.store.select(RouterState).pipe(takeUntil(this.unsubscribe)).subscribe(routerState => {
      this.currentRoute = routerState?.state.url;
    });

    this.userData = this.userService.currentUser;

    this.visibilityManagerService.showMenu();

    this.setMenuPermissions();

    this.actions$.pipe(ofActionDispatched(ChangeOrganisation), takeUntil(this.unsubscribe)).subscribe(() => {
      this.setMenuPermissions();
    });

    this.actions$.pipe(ofActionDispatched(UserOrganisationRefreshed), takeUntil(this.unsubscribe)).subscribe(() => {
      this.userData = this.userService.currentUser;
      this.setMenuPermissions();
    });

    this.actions$.pipe(ofActionDispatched(LanguageChangeAction), takeUntil(this.unsubscribe)).subscribe(() => {
      this.buildMenu();
    });

    this.selectedLanguage = this.uiLanguageService.activeLanguage;
    this.translateService.onLangChange.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.store.dispatch(new LanguageChangeAction());
    });

    this.setIsMobileDevice();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  onNavigate(menuLink: { link?: string, isExternal?: boolean, isRedirect?: boolean }): void {
    if (!menuLink.link) {
      return;
    }
    if (menuLink.isRedirect) {
      window.open(menuLink.link);

      return;
    }

    if (!this.discardChangesService.isDirty()) {
      this.navigateTo(menuLink.link, menuLink.isExternal);
      this.currentRoute = menuLink.link;

      return;
    }

    this.confirmationService.confirm({
      key: 'warning-confirmation',
      header: this.translateService.instant('DiscardChanges.DiscardChanges_Title'),
      message: this.translateService.instant('DiscardChanges.DiscardChanges_Content'),
      accept: () => {
        this.discardChangesService.clearAllDirty();
        this.navigateTo(menuLink.link, menuLink.isExternal);
        this.currentRoute = menuLink.link;
      }
    });
  }

  onLanguageChange(value: any): void {
    this.uiLanguageService.setActiveLanguage(value);
    this.translateService.use(value);
    this.selectedLanguage = this.uiLanguageService.activeLanguage;
  }

  public onLogout(event: any): void {
    this.authService.logout(event, true);
  }

  public onCookieSettings(): void {
    this.store.dispatch(new ResetCookieConsent());
  }

  @HostListener('window:resize', ['$event'])
  public onWindowResize(): void {
    this.store.dispatch(new TecComPortalResizeAction());
  }

  @HostListener('window:scroll', ['$event.target'])
  private onWindowScroll(): void {
    if (!this.isMobileDevice) {
      return;
    }
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (offset > this.currentPosition) {
      this.isShowTopNavigation = false;
    }
    if (offset < this.currentPosition) {
      this.isShowTopNavigation = true;
    }
    this.currentPosition = offset;
  }

  private navigateTo(link: string, external: boolean): void {
    window.dispatchEvent(new Event('gototecwebpage'));
    if (external) {
      this.authService.clearAuthData();
      window.location.href = link;

      return;
    }

    this.store.dispatch(new Navigate([link]));
  }

  private setIsMobileDevice(): void {
    this.isMobileDevice = this.menuService.checkMobileDevice();
    this.actions$.pipe(ofActionDispatched(TecComPortalResizeAction), takeUntil(this.unsubscribe)).subscribe(() => {
      this.isMobileDevice = this.menuService.checkMobileDevice();
    });
  }

  private setMenuPermissions(): void {
    this.isAdmin = this.userService.isAdmin();
    this.isSupplier = this.currentOrganizationService.isSupplier();
    this.isTecConnect = this.currentOrganizationService.currentOrganization?.tecConnectEnabled;
    this.isWorkshop = this.currentOrganizationService.currentOrganization?.category
                    === OrganisationCategoryEnum.Workshop;
    this.isReturns = this.currentOrganizationService.currentOrganization?.returnsEnabled;
    this.permissions = [];
    const hasRequestOrderPermission = this.currentOrganizationService.currentOrganization?.requestOrderEnabled
      ? this.userService.hasPermission(Permissions.ShowRequestAndOrder)
      : this.currentOrganizationService.currentOrganization?.requestOrderEnabled;
    if (hasRequestOrderPermission) {
      this.permissions.push(Permissions[Permissions.ShowRequestAndOrder]);
    }
    const hasJournalPermission = this.currentOrganizationService.currentOrganization?.journalEnabled
      ? this.userService.hasPermission(Permissions.ShowJournal)
      : this.currentOrganizationService.currentOrganization?.journalEnabled;
    if (hasJournalPermission) {
      this.permissions.push(Permissions[Permissions.ShowJournal]);
    }
    if (this.userService.hasPermission(Permissions.ShowInvoices)) {
      this.permissions.push(Permissions[Permissions.ShowInvoices]);
    }
    if (this.userService.hasPermission(Permissions.ShowTransactions)) {
      this.permissions.push(Permissions[Permissions.ShowTransactions]);
    }
    if (this.userService.hasPermission(Permissions.EditWarrantyReturns)) {
      this.permissions.push(Permissions[Permissions.EditWarrantyReturns]);
    }
    if (this.userService.hasPermission(Permissions.CreateWarrantyReturns)) {
      this.permissions.push(Permissions[Permissions.CreateWarrantyReturns]);
    }
    if (this.userService.hasPermission(Permissions.SetupWarrantyReturns)) {
      this.permissions.push(Permissions[Permissions.SetupWarrantyReturns]);
    }
    this.isArticleData = this.currentOrganizationService.currentOrganization?.articleDataEnabled;

    this.isReturnsBetaEnabled = environment.enableReturnsBeta;
    this.isOrderManagerBetaEnabled = environment.enableOrderManagerBeta;
    this.userRoles = this.userData.roles;

    this.setMenuParams();
  }

  private setMenuParams(): void {
    this.menuParams = {
      isAdmin: this.isAdmin,
      isReturns: this.isReturns,
      isReturnsBeta: this.isReturnsBetaEnabled,
      isSupplier: this.isSupplier,
      isTecConnect: this.isTecConnect,
      isOrderManagerBeta: this.isOrderManagerBetaEnabled,
      permissions: this.permissions,
      userRoles: this.userRoles,
      isWorkshop: this.isWorkshop,
      isArticleData: this.isArticleData
    };
  }

  private buildMenu(): void {
    this.menuSettings = {
      mainNav: this.menuService.buildMenu(MenuService.MenuResourceKeys.MainNav),
      mobileNav: this.menuService.buildMenu(MenuService.MenuResourceKeys.MobileNav),
      footer: this.menuService.buildMenu(MenuService.MenuResourceKeys.Footer)
    };
  }
}
