import { PipeTransform, Pipe } from '@angular/core';

import { ClaimStatusType } from '@auth/components/notification-menu/models/claim-status-type.enum';

@Pipe({
  name: 'claimNotificationStatus'
})
export class ClaimNotificationStatusPipe implements PipeTransform {
  transform(value: any): any {
    const claimStates = ClaimStatusType;
    const state = claimStates[value];
    if (state) {
      return `Claims.NotificationStatus_${state}`;
    }

    return value;
  }
}
