import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { UserIdentityService } from '@auth/services/user-identity.service';
import { CurrentOrganizationService } from '@auth/services/current-organization.service';
import { UserProfileService } from '@auth/services/user-profile.service';
import { ChangeOrganisation } from '@auth/state/actions/organization/change-organisation.action';
import { Permissions } from '@auth/models/permission.enum';
import { UserModel } from '@auth/models/user/user.model';
import { OrganisationMappingModel } from '@auth/models/user/organisation-mapping.model';
import { LocaleSettingsService } from '@core/services/locale-settings.service';
import { RoleType } from '@core/models/authentication/role-type.enum';

@Injectable()
export class UserService {
  public currentCulture = 'en';
  public currentTimezoneOffset = '';
  public currentTimeZoneId = '';
  public currentDateFormat = '';
  public currentDateFormatRegex: RegExp;
  public currentTimeFormat = '';
  public currentDatepickerFormat = '';
  public currentDecimalSeparator = '.';
  public currentGroupSeparator = ',';

  private _currentUser: UserModel;

  constructor(
    private identity: UserIdentityService,
    private store: Store,
    private userProfile: UserProfileService,
    private currentOrganizationService: CurrentOrganizationService,
    private localeSettingsService: LocaleSettingsService) { }

  get currentUser(): UserModel {
    return this._currentUser;
  }

  public fetchUserData(): Observable<UserModel> {
    return this.userProfile.getUser()
      .pipe(
        mergeMap(user => this.identity.userIdentity().pipe(map(userIdentity => {
          let orgId = 1;
          user.userOrganisationMappings.forEach(org => {
            org.val = orgId++;
          });
          user.roles = userIdentity.roles;
          this._currentUser = user;

          // todo: test this and consider remove if
          if (user.userProfile) {
            this.currentCulture = user.userProfile.culture;
            this.currentTimezoneOffset = user.userProfile.utcHourOffset;
            this.currentDateFormat = user.userProfile.dateFormat;
            this.currentTimeFormat = user.userProfile.timeFormat;
            this.currentDecimalSeparator = user.userProfile.decimalSeparator;
            this.currentGroupSeparator = user.userProfile.groupSeparator;
            this.currentTimeZoneId = user.userProfile.timeZoneId;
          }

          this.localeSettingsService.culture = user.userProfile.culture;
          this.localeSettingsService.dateFormat = user.userProfile.dateFormat;
          this.localeSettingsService.timeFormat = user.userProfile.timeFormat;
          this.localeSettingsService.decimalSeparator = user.userProfile.decimalSeparator;

          this.setOrganization(user);

          return user;
        }))));
  }

  public isAdmin(): boolean {
    const currentMapping = this.currentUser.userOrganisationMappings.find(m =>
      m.tecComId === this.currentOrganizationService.getCurrentOrganization().tecComId);
    if (currentMapping) {
      return currentMapping.role.id === 2;
    }

    return false;
  }

  public isTecComAdmin(): boolean {
    return this.currentUser.roles.some(role => role.toLowerCase() === RoleType.Admin.toLowerCase());
  }

  public hasPermission(permission: Permissions): boolean {
    const currentMapping = this.currentUser.userOrganisationMappings.find(m =>
      m.tecComId === this.currentOrganizationService.getCurrentOrganization().tecComId);
    if (currentMapping) {
      const perm = currentMapping.permissions.find(p =>
        p.permission === permission);
      if (perm) {
        return perm.isActive;
      }
    }

    return false;
  }

  private setOrganization(currentUser: UserModel): void {
    if (!currentUser.userOrganisationMappings || currentUser.userOrganisationMappings.length < 1) {
      return;
    }

    let currentOrgTecId = this.currentOrganizationService.getSelectedOrganizationTecId();

    if (!(currentOrgTecId &&
      currentUser.userOrganisationMappings.some(
        (mapping: OrganisationMappingModel) => mapping.tecComId === currentOrgTecId))) {
      currentOrgTecId = currentUser.userOrganisationMappings[0].tecComId;
    }

    const currentSalesOrg = currentUser.userOrganisationMappings
      .find(e => e.tecComId === currentOrgTecId) || currentUser.userOrganisationMappings[0];

    this.store.dispatch(new ChangeOrganisation(currentSalesOrg.tecComId));
  }
}
