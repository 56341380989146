import { Pipe, PipeTransform } from '@angular/core';

import { RequestStatusType } from '@auth/components/notification-menu/models/request-status.type.enum';

@Pipe({
  name: 'notificationStatusTranslator'
})
export class NotificationStatusTranslatorPipe implements PipeTransform {
  transform(value: any): any {
    if (value === RequestStatusType.Error) {
      return 'NotificationMenu.Error';
    }

    if (value === RequestStatusType.InProgress) {
      return 'NotificationMenu.InProgress';
    }

    if (value === RequestStatusType.Success) {
      return 'NotificationMenu.Success';
    }

    return value;
  }

}
