import { Component } from '@angular/core';

import { SpinnerService } from '@shared/services/spinner.service';

@Component({
  selector: 'ta-spinner',
  templateUrl: './ta-spinner.component.html',
  styleUrls: ['./ta-spinner.component.scss']
})
export class TaSpinnerComponent {
  constructor(public spinnerService: SpinnerService) { }
}
