<svg (click)="onClickOrderProTooltip.emit()" class="question-mark-circle" width="19" height="19" viewBox="0 0 19 19"
     fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_dd_6383_74766)">
    <circle cx="9.42969" cy="9.31006" r="8" fill="white"/>
    <circle class="outline-circle" cx="9.42969" cy="9.31006" r="7.5" stroke="#D3D4D7"/>
  </g>
  <path class="question-mark"
    d="M8.54499 11.3677H10.1961C10.3026 11.3677 10.3736 11.3147 10.3736 11.2354V10.8516C10.3736 10.4547 10.4624 10.2695 10.7287 10.0181L12.1312 8.58909C12.6283 8.09953 12.8591 7.60997 12.8591 7.04102C12.8591 5.69143 11.4743 4.73877 9.52144 4.73877C7.56855 4.73877 6.2903 5.55911 6.00624 6.88225C5.98849 6.96164 6.02399 7.02779 6.14827 7.04102L7.74608 7.22626C7.8526 7.23949 7.92362 7.1998 7.94137 7.12041C8.10115 6.44561 8.61601 6.0619 9.45042 6.0619C10.3026 6.0619 10.8352 6.48531 10.8352 7.05425C10.8352 7.4115 10.6577 7.71582 10.2316 8.13922L8.82905 9.56821C8.50949 9.89899 8.36746 10.1636 8.36746 10.6929V11.2354C8.36746 11.3147 8.43847 11.3677 8.54499 11.3677ZM8.29644 13.7493C8.29644 13.8287 8.36746 13.8816 8.47398 13.8816H10.2493C10.3559 13.8816 10.4269 13.8287 10.4269 13.7493V12.3997C10.4269 12.3203 10.3559 12.2674 10.2493 12.2674H8.47398C8.36746 12.2674 8.29644 12.3203 8.29644 12.3997V13.7493Z"
    fill="#3D3D4C"/>
  <defs>
    <filter id="filter0_dd_6383_74766" x="0.429688" y="0.310059" width="18" height="18" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                     result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="0.5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6383_74766"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                     result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="0.5"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"/>
      <feBlend mode="normal" in2="effect1_dropShadow_6383_74766" result="effect2_dropShadow_6383_74766"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6383_74766" result="shape"/>
    </filter>
  </defs>
</svg>
