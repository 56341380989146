import { Component, OnInit } from '@angular/core';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CurrentOrganizationService } from '@auth/services/current-organization.service';
import { OrganisationMappingModel } from '@auth/models/user/organisation-mapping.model';
import { ChangeOrganisation } from '@auth/state/actions/organization/change-organisation.action';
import { DiscardChangesService } from '@auth/services/discard-changes.service';
import { TranslationsService } from '@core/translations/translations.service';
import { FetchOrganizationSelection } from '@auth/state/actions/organization/fetch-organization-selection.action';
import { FetchOrganizationSelectionSuccess } from '@auth/state/actions/organization/fetch-organization-selection-success.action';
import { UserOrganisationRefreshed } from '@auth/state/actions/user/user-organisation-refreshed.action';

@Component({
  selector: 'tec-organization-selection',
  templateUrl: './organization-selection.component.html',
  styleUrls: ['./organization-selection.component.scss']
})
export class OrganizationSelectionComponent implements OnInit {
  public isShowSubTitle?: boolean;
  public organizationSelected: OrganisationMappingModel;
  public organizationList: OrganisationMappingModel[] = [];
  public isDirty = false;

  private translations: string[];
  private unsubscribe: Subject<void> = new Subject();

  constructor(private translationsService: TranslationsService,
    private confirmationService: ConfirmationService,
    private discardChangesService: DiscardChangesService,
    private currentOrganizationService: CurrentOrganizationService,
    private actions$: Actions,
    private store: Store) {
  }

  ngOnInit(): void {
    this.setOrganizationSelection();
    this.discardChangesService.dirtyStateChange.subscribe((isDirty: boolean) => this.isDirty = isDirty);
    this.translationsService.get([
      'DiscardChanges.DiscardChanges_Title',
      'DiscardChanges.DiscardChanges_Content'
    ]).subscribe(result => {
      this.translations = result;
    });
    this.fetchOrganizationSelection();
  }

  public getSelectedOrgBackgroundColor(): string {
    return this.currentOrganizationService.currentOrganization?.colour ?
      this.currentOrganizationService.currentOrganization.colour : '#ffffff';
  }

  public onOrgChanged(org: OrganisationMappingModel): void {
    if (!this.discardChangesService.isDirty()) {
      this.handleChangeOrganization(org);

      return;
    }

    this.confirmationService.confirm({
      key: 'header-info-confirmation',
      header: this.translations['DiscardChanges.DiscardChanges_Title'],
      message: this.
        translations['DiscardChanges.DiscardChanges_Content'],
      accept: () => {
        this.discardChangesService.clearAllDirty();
        this.handleChangeOrganization(org);
      },
      reject: () => {
        this.store.dispatch(new FetchOrganizationSelection());
      }
    });
  }

  public getContrastYIQ(hexcolor: string): string {
    if (hexcolor.startsWith('#')) {
      // eslint-disable-next-line no-param-reassign
      hexcolor = hexcolor.substr(1);
    }
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    return (yiq >= 128) ? 'black' : 'white';
  }

  public getOrgBackgroundColor(organization: OrganisationMappingModel): string {
    return organization.colour ? organization.colour : '#ffffff';
  }

  private fetchOrganizationSelection(): void {
    this.store.dispatch(new FetchOrganizationSelection());
    this.actions$.pipe(ofActionDispatched(UserOrganisationRefreshed), takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.store.dispatch(new FetchOrganizationSelection());
      });
  }

  private setOrganizationSelection(): void {
    this.actions$.pipe(ofActionDispatched(FetchOrganizationSelectionSuccess), takeUntil(this.unsubscribe))
      .subscribe((action: FetchOrganizationSelectionSuccess) => {
        const payload = action.payload;
        this.organizationSelected = payload.organizations
          .find(org => org.tecComId === payload.currentOrganization.tecComId);
        this.organizationList = payload.organizations;
      });
  }

  private handleChangeOrganization(org: OrganisationMappingModel): void {
    this.store.dispatch(new ChangeOrganisation(org.tecComId));
  }
}
