export enum ClaimStatusType {
    Draft = 0,
    Finished = 1,
    Sent = 2,
    New = 3,
    Decided = 4,
    Decision = 5,
    Pending = 6,
    InWork = 7,
    DecidedManually = 8,
    ReceiverCreated = 9,
    SelfCreated = 10
}
