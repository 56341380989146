import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { HttpOptionsModel } from '@core/models/http/http-options.model';

@Injectable({
  providedIn: 'root'
})
export class TaHttpClient {
  constructor(private http: HttpClient) { }

  get<TResult>(
    urlPath: string,
    options?: {
      queryModel?: any,
      headers?: HttpHeaders,
      responseType?: string
    }): Observable<TResult | any> {
    return this.RunAsync((baseUrl: string) =>
      this.http.get<TResult>(
        `${baseUrl}/${urlPath}`,
        this.createHttpOptionsModel(options?.headers, options?.queryModel, options?.responseType)));
  }

  post<T, TResult>(
    urlPath: string,
    body: T,
    headers?: HttpHeaders): Observable<TResult | any> {
    return this.RunAsync((baseUrl: string) =>
      this.http.post<TResult>(`${baseUrl}/${urlPath}`, body, this.createHttpOptionsModel(headers)));
  }

  put<T, TResult>(
    urlPath: string,
    body: T,
    headers?: HttpHeaders): Observable<TResult | any> {
    return this.RunAsync((baseUrl: string) =>
      this.http.put<TResult>(`${baseUrl}/${urlPath}`, body, this.createHttpOptionsModel(headers)));
  }

  patch<T, TResult>(
    urlPath: string,
    body: T,
    headers?: HttpHeaders): Observable<TResult | any> {
    return this.RunAsync((baseUrl: string) =>
      this.http.patch<TResult>(`${baseUrl}/${urlPath}`, body, this.createHttpOptionsModel(headers)));
  }

  delete<TResult>(urlPath: string, headers?: HttpHeaders): Observable<TResult | any> {
    return this.RunAsync((baseUrl: string) =>
      this.http.delete<TResult>(`${baseUrl}/${urlPath}`, this.createHttpOptionsModel(headers)));
  }

  private createHttpOptionsModel(
    headers?: HttpHeaders,
    queryModel?: any,
    responseType = 'json'): any {
    const options = {} as HttpOptionsModel;

    if (headers) {
      options.headers = headers;
    }

    if (queryModel) {
      let httpParams = new HttpParams();

      Object.keys(queryModel).forEach(k => {
        if (queryModel[k]) {
          httpParams = httpParams.set(k, queryModel[k]);
        }
      });

      options.params = httpParams;
    }

    return { ...options, observe: 'response', responseType };
  }

  private RunAsync<TResult>(httpCall: (baseUrl: string) => Observable<HttpEvent<TResult>>): Observable<TResult> {
    return new Observable<TResult>(subscriber => {
      httpCall('api').pipe(
        map((event: HttpEvent<TResult>) => {
          if (event instanceof HttpResponse) {
            return event.body;
          }

          return throwError(event);
        }),
        catchError((error: any) => {
          if (error instanceof HttpResponse) {
            return error.body;
          }

          return throwError(error);
        })).subscribe(subscriber);
    });
  }
}
