<div class="order-pro-container">
  <img [style.width.px]="size"
    *ngIf="!isShowOnlyTxml5"
    class="mr-2"
    src="{{orderProLogoUrl}}"
    alt="order-pro"/>
  <img class="mr-2" *ngIf="isShowOnlyTxml5"
    src="assets/images/order-pro/txml5-only-badge.svg"
    alt="order-pro"/>
  <question-mark
    id="order-pro-tooltip"
    [popover]="popTemplate"
    [outsideClick]="true"
    [container]="'body'"
    [placement]="'right'">
  </question-mark>
  <ng-template #popTemplate>
    <div class="order-pro-info">
      <div class="d-flex order-pro-info-title">
        <div class="mr-2">{{'Help.Help_OrderProTitle' | translate}}</div>
        <img class="order-pro-free-badge" src="assets/images/order-pro/order-pro-free.svg"/>
      </div>
      <div class="mb-2">{{'Help.Help_OrderPro_Introduction' | translate}}</div>
      <mat-accordion>
        <mat-expansion-panel expanded="true" togglePosition="before">
          <mat-expansion-panel-header>
            <div class="d-flex justify-content-between">
              <mat-panel-title>
                <img class="pb-1 order-pro-benefit-icon excellent-program"
                  src="assets/images/order-pro/excellent-program.svg"
                  alt="order-pro"/>
              </mat-panel-title>
              <mat-divider vertical class="mr-3"></mat-divider>
              <mat-panel-description>{{'Help.Help_OrderPro_ExcellentProgram_Title' | translate}}</mat-panel-description>
            </div>
          </mat-expansion-panel-header>
          <div class="order-pro-advertise-img">
            <img src="assets/images/order-pro/excellent-program-img.svg"/>
          </div>
          <div>{{'Help.Help_OrderPro_ExcellentProgram_Content' | translate}}</div>
          <a (click)="onClickOrderProHelp()">{{'Help.Help_OrderPro_ExcellentProgram_Help' | translate}}</a>
        </mat-expansion-panel>
        <mat-expansion-panel expanded="false" togglePosition="before">
          <mat-expansion-panel-header>
            <div class="d-flex justify-content-between">
              <mat-panel-title>
                <img class="pb-1 order-pro-benefit-icon txml5"
                  src="assets/images/order-pro/txml5.svg"
                  alt="order-pro"/>
              </mat-panel-title>
              <mat-divider vertical class="mr-3"></mat-divider>
              <mat-panel-description>{{'Help.Help_OrderPro_TXML5_Title' | translate}}</mat-panel-description>
              <img class="order-pro-benefit-coming-soon" src="assets/images/order-pro/coming-soon.svg"/>
            </div>
          </mat-expansion-panel-header>
          <div class="order-pro-advertise-img"><img src="assets/images/order-pro/txml5-img.svg"/></div>
          <div>{{'Help.Help_OrderPro_TXML5_Content' | translate}}</div>
        </mat-expansion-panel>
        <mat-expansion-panel expanded="false" togglePosition="before">
          <mat-expansion-panel-header>
            <div class="d-flex justify-content-between">
              <mat-panel-title>
                <img class="pb-1 order-pro-benefit-icon match-making"
                  src="assets/images/order-pro/match-making.svg"
                  alt="order-pro"/>
              </mat-panel-title>
              <mat-divider vertical class="mr-3"></mat-divider>
              <mat-panel-description>{{'Help.Help_OrderPro_Matchmaking_Title' | translate}}</mat-panel-description>
              <img src="assets/images/order-pro/coming-soon.svg"/>
            </div>
          </mat-expansion-panel-header>
          <div class="order-pro-advertise-img"><img src="assets/images/order-pro/match-making-img.svg"/></div>
          <div>{{'Help.Help_OrderPro_Matchmaking_Content' | translate}}</div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-template>
</div>
