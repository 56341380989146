export class StringHelper {
  public static format(value: string, args: any[]): string {
    let result: string = value;
    if (result !== null && args !== null && args.length > 0) {
      args.forEach((arg, argIndex) => {
        result = result.replace(new RegExp(`\\{${  argIndex  }\\}`, 'gi'), arg !== null ? arg : '');
        // eslint-disable-next-line no-param-reassign
        argIndex++;
      });
    }

    return result;
  }

  public static replaceAll(target, search, replacement): string {
    return target.split(search).join(replacement);
  }
}

export {};
