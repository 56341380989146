export enum Permissions {
 ShowListPrices = 1,
 ShowNetPrices = 2,
 ShowReceivedDocuments = 10,
 ShowJournal = 11,
 ShowTransactions = 12,
 ShowRequestAndOrder = 13,
 ShowInvoices = 14,
 EditWarrantyReturns = 15,
 CreateWarrantyReturns = 16,
 SetupWarrantyReturns = 17
}
