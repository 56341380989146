<div class="d-flex justify-content-between m-0 pointer" [ngClass]="{'disabled': model.claimStatus===0}"
  [title]="'NotificationMenu.NotSelectable'|translate">
  <div class="p-0 truncate-text">
    <i class="ta-icon-process pull-left"></i>
    <div class="name type pull ml-5">{{getFormattedDateTime(model.date)}}</div>
  </div>
  <div class="p-0 d-flex">
    <div class="status">
      {{model.claimStatus|claimNotificationStatus|translate}}
      {{model.claimCategory|claimNotificationCategory|translate}}
    </div>
    <i class="fa fa-trash mt-1" (click)="onDelete($event)"></i>
  </div>
</div>
