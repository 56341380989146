<div [@openClose]="((cookieConsent$|async) != cookieConsentConst.Allow && (cookieConsent$|async) != cookieConsentConst.Deny) ? 'open' : 'closed'"
  class="cookie-consent-banner">
  <div class="cookie-consent-wrapper">
    <div class="title">
      <h4>{{'CookieConsent.Title'|translate}}</h4>
    </div>
    <div class="cookie-consent">
      <div class="message">
        {{'CookieConsent.Message'|translate}}
        <hr>
        <p>{{'CookieConsent.Message_P1'|translate}}</p>
        <p>{{'CookieConsent.Message_P2'|translate}}</p>
        <div class="cookie-info">
          <div class="cookie-message"></div>
          <mat-accordion [hideToggle]="true">
            <mat-accordion class="grpd-disclaimer" [hideToggle]="true">
              <mat-expansion-panel class="mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <a>{{'CookieConsent.GDPR_Disclaimer_Title'|translate}}</a>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div><p [innerHTML]="'CookieConsent.GDPR_Disclaimer_Content' | translate | replace: [lgpdDownloadHyperLink] | safeHtml"></p></div>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-expansion-panel class="mat-elevation-z0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <a>{{'CookieConsent.ServicesInformation'|translate}}</a>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <b>{{'CookieConsent.Application_Insights'|translate}}</b>
                <ul>
                  <li>{{'CookieConsent.AppInsights_Purpose'|translate}}</li>
                  <li>{{'CookieConsent.AppInsights_Use'|translate}}</li>
                  <li>{{'CookieConsent.AppInsights_Provider'|translate}}</li>
                  <li>{{'CookieConsent.PrivacyPolicy'|translate}}:
                    <a [href]="'CookieConsent.AppInsights_PrivacyPolicy'|translate"
                      target="_blank">{{'CookieConsent.AppInsights_PrivacyPolicy'|translate}}</a>
                  </li>
                  <li>{{'CookieConsent.AppInsights_USDataProcessing'|translate}}</li>
                </ul>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div>
          <a [href]="privacyUrl?.link" target="_blank">{{'CookieConsent.PrivacyPolicy'|translate}}</a>
        </div>
      </div>
      <div class="consent">
        <button (click)="setCookieConsent('allow')" color="accent"
          mat-raised-button>{{'CookieConsent.Allow'|translate}}</button>
        <button (click)="setCookieConsent('deny')" mat-raised-button>{{'CookieConsent.Deny'|translate}}</button>
      </div>
    </div>
  </div>
</div>
