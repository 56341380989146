import { Inject, Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrganisationMappingModel } from '@auth/models/user/organisation-mapping.model';
import { UserService } from '@auth/services/user.service';
import { CurrentOrganizationService } from '@auth/services/current-organization.service';
import { AppSetting, APP_SETTING } from '@app/core/app-settings';
import { FetchOrganizationSelection } from '@auth/state/actions/organization/fetch-organization-selection.action';
import { FetchOrganizationSelectionSuccess } from '@auth/state/actions/organization/fetch-organization-selection-success.action';
import { Permissions } from '@auth/models/permission.enum';

export interface UserOrganizationStateModel {
  organizations: OrganisationMappingModel[];
  currentOrganization: OrganisationMappingModel;
}

@State<UserOrganizationStateModel>({
  name: 'organizationSelection',
  defaults: {
    organizations: [],
    currentOrganization: {}
  } as UserOrganizationStateModel
})
@Injectable()
export class OrganizationSelectionState {
  constructor(private userService: UserService,
    private currentOrganizationService: CurrentOrganizationService,
    @Inject(APP_SETTING) private appSetting: AppSetting) {
  }

  @Action(FetchOrganizationSelection)
  public fetchUserOrganization(ctx: StateContext<UserOrganizationStateModel>): void {
    const currentOrganization = this.currentOrganizationService.getCurrentOrganization();
    const organizations = this.handleOrganizationByUrl();
    ctx.dispatch(new FetchOrganizationSelectionSuccess({ organizations, currentOrganization }));
  }

  @Action(FetchOrganizationSelectionSuccess)
  public fetchUserOrganizationSuccess(ctx: StateContext<UserOrganizationStateModel>,
    action: FetchOrganizationSelectionSuccess): void {
    const state = ctx.getState();
    const organizations = action.payload.organizations;
    const currentOrganization = action.payload.currentOrganization;
    ctx.setState({ ...state, currentOrganization, organizations });
  }

  private handleOrganizationByUrl(): OrganisationMappingModel[] {
    const organizationList = this.userService.currentUser.userOrganisationMappings ?? [];
    const isBasket = window.location.href.includes(this.appSetting.txml5BasketRoute);

    return isBasket
      ? organizationList.filter(x => x.permissions.some(p => p.permission === Permissions.ShowRequestAndOrder)
        && x.requestOrderEnabled)
      : organizationList;
  }
}
